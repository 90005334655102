<template>
    <v-col
        v-if="typeof field.condition === 'string'
        ? additionalData[field.condition]
        : true"
        v-bind="{cols: getFieldSize(field)}"
        class="mb-0 pb-0 pt-0"
    >
        <div v-for="item in fields" :key="item.label">
            <v-expand-transition>
                <component
                    :is="fieldTypes[item.fieldType || 'text']"
                    v-if="((item.isMobile === false ? !$root.isMobile : $root.isMobile) && item.isMobile !== undefined) || !(item.visible === false)"
                    v-model="item.value"
                    v-bind="item"
                    @input="item.input"
                    @select="value => item.selected = value"
                    :class="anketaName"
                    :outlined="outlined"
                    :id="inputId"
                    :anketaName="anketaName"
                    >
                </component>
            </v-expand-transition>
        </div>
    </v-col>
</template>

<script>
import label from "./fields/Label";
import text from "./fields/Text";
import date from "./fields/Date";
import select from "./fields/Select";
import slider from "./fields/Slider";
import checkbox from "./fields/Checkbox";
import flexible from "./fields/Flexible";
import file from "./fields/File";
import autocomplete from "./fields/Autocomplete";
import promocode from "./fields/Promocode.vue";

export default {
    props: {
        anketaName: String,
        outlined: Boolean,
        cols: String,
        altCols: Object,
        inputId: String
    },
    data: () => ({
        fieldTypes: {
            label,
            text,
            date,
            select,
            slider,
            flexible,
            checkbox,
            file,
            autocomplete,
            promocode
        },
    }),
    computed: {
        field() {
            return this.$root.anketa[this.anketaName];
        },

        fields() {
            return (
                this.$root.anketa[this.anketaName].fields || [
                    this.$root.anketa[this.anketaName],
                ]
            );
        },

        additionalData() {
            return this.$root.anketa.additionalData;
        },

        isMobile() {
			return this.$vuetify.breakpoint.xs;
		},

        
    },

    methods: {
        getFieldSize(field){
            let grid = null
            if(this.altCols !== undefined){
                grid = this.altCols
            }else {
                grid = field.grid
            }
            let currentSize = this.$vuetify.breakpoint.name
            if(currentSize in grid){
                return grid[currentSize]
            }

        }
    },

};
</script>
