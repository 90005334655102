<template>
  <v-app>
    <v-main>
      <NordWindMainPage v-if="appType('nordwind')"
        class="nordwind"
      />
      <MainPage v-if="appType('cash')"
        class="cash"
      />

      <VtbMainPage
        v-if="appType('vtb')"
        class="vtb"
      />
    </v-main>
  </v-app>
</template>

<script>
import MainPage from './components/MainPage';
import NordWindMainPage from './components/NordWindMainPage.vue';
import VtbMainPage from './components/VtbMainPage.vue';

export default {
  name: 'App',
  components: {
    MainPage,
    NordWindMainPage,
    VtbMainPage
  },
  computed: {
    isNordwind(){
      return this.$root.state.isNordwind()
    },
    
		
  },

  mounted() {
    console.log('type of dev', this.$root.state.typeOfDev())
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.$root.anketa.windowWidth = window.innerWidth
      });
    })

      setTimeout(() => {
        this.$root.mindbox("Пробыл на сайте более 30 сек");
      }, 30000)
  },

  methods: {
    appType(type){
      if(type === this.$root.state.type()){
        return true
      }else {
        return false
      }
    }
  }

  

};
</script>

<style lang="scss" scoped>

</style>
