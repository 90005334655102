<template>
    <div :key='componentKey'>
        <div class='block-2' >
            <div class='container-width'>
                <div class='block-2-body'>
                    <div class='back-logo'>
                        <img class='back-logo-image' src='../assets/img/pllogo48.svg' alt='logotype'/>
                    </div>
                    <div class='block-2-announce'>
                        <div class='announce-description'>
                            <!--img class='announce-image' src='../assets/img/icon5.png' alt/-->

                            <h1>
                                <!-- {{ !anketa.actionInfo ? "Кредит наличными на Вашу карту" : `Кредит наличными на любые траты` }}
                                 -->
                                <div data-popmechanic-embed="72227"></div>
                                <br>Вам доступен кредит<sup>*</sup> наличными на любые цели!
                            </h1>

                            <div v-if='anketa.actionInfo'>
                                <p
                                    v-if="anketa.initialPayment.value < 40000"
                                    class='h1-description'
                                    id='credit-no-discount'

                                >
                                    Перечислим деньги на карту за 2 минуты<sup class="other">**</sup>

                                </p>
                                <p
                                    v-else-if="anketa.initialPayment.value >= 40000"
                                    class='h1-description'
                                    id='credit-no-discount'

                                >
                                    Перечислим деньги на карту за 2 минуты<sup class="other">**</sup>

                                </p>
                                <!-- <p class='h1-description' id='credit-discount' v-show='templateText'>

                                    Заполнение анкеты не требуется
                                </p> -->


                            </div>

                            <ul v-if='!anketa.actionInfo' class='list main-list'>
                                <li>ставка - от 1,9% в месяц</li>
                                <li>срок - до 12/36 месяцев</li>
                                <li>моментально отправим деньги</li>
                            </ul>
                        </div>

                        <div class='container-info sliders' id='widget' v-show='showSliderComplited.status'>
                            <Slider
                                v-model='anketa.initialPayment.value'
                                v-bind='anketa.initialPayment'
                                :nameSlider='sliderinitialPayment'
                                :min='MinSum'
                                :max='MaxSum'
                                :step='1000'
                                @input='anketa.initialPayment.input'
                                :sliderColors="{bg:'#eb3b66', track: 'grey'}"
                            >

                                <div class='container-info-limit slider-summ' slot='labelSliderSumm'>
                                    <p
                                        class='container-info-limit_description container-info-limit_description__ticks'
                                    >{{ minSumForDown }} &#8381;</p>
                                    <p
                                        class='container-info-limit_description container-info-limit_description__ticks'
                                    >
                                        {{ maxSumForDown }}
                                        <span>&#8381;</span>
                                    </p>
                                </div>
                            </Slider>

                            <Slider
                                v-model='anketa.term.value'
                                v-bind='anketa.term'
                                :tick-size='sliderPeriodSTR.length'
                                :slider-array='sliderPeriod'
                                :tick-labels='sliderPeriodSTR'
                                :nameSlider='sliderTerm'
                                :min='1'
                                :max='sliderPeriod.length'
                                :step='1'
                                @input='anketa.term.input'
                                :sliderColors="{bg:'#eb3b66', track: 'grey'}"
                                :metricGoalName="'slider_term'"
                            />

                            <!--<div class="container-info-limit_slider" id="slider-period"></div>-->

                            <Condition v-model='anketa.conditions.monthlyPayment'>Ежемесячный платеж</Condition>
                            <p></p>
                            <div v-if='anketa.actionInfo && params === "ac"' class='container-info-cashback'>
                                <div class='container-info-cashback-title'>
                                    <!--img v-if='anketa.term.valueTerm === 12' src='@/assets/img/icon8.png'
                                         class='announce-image' alt/-->
                                    <!--img v-if='anketa.term.valueTerm !== 12' src='@/assets/img/image 54image-none.png'
                                         class='announce-image' alt/-->
                                    <!--									<img :src="anketa.term.valueTerm >= 12 ? '@/assets/img/icon-money.png' : '@/assets/img/icon8.png'" class="announce-image" alt/>-->
                                    <h1>
                                        Ваш кешбэк: {{ anketa.term.valueTerm === 12 ? cashback : "0" }}
                                        <b class='ruble-icon'>&#8381;</b>
                                    </h1>
                                </div>
                                    <p>
                                        {{
                                            anketa.term.valueTerm === 12 ?
                                                "":
                                                "Чтобы получить кешбэк, выберите срок 12 месяцев"
                                        }}
                                    </p>
                                    <p class="pointer more" @click="$vuetify.goTo('#cashback', 500)">
                                        Подробнее
                                    </p>
                            </div>

                            <div v-if='anketa.actionInfo && params === "acnew"' class='container-info-cashback'>
                                <div class='container-info-cashback-title'>
                                    <!-- <img v-if='anketa.term.valueTerm !== 1' src='@/assets/img/icon8.png'
                                         class='announce-image' alt/>
                                    <img v-if='anketa.term.valueTerm === 1' src='@/assets/img/image 54image-none.png'
                                         class='announce-image' alt/> -->
                                    <!--									<img :src="anketa.term.valueTerm >= 12 ? '@/assets/img/icon-money.png' : '@/assets/img/icon8.png'" class="announce-image" alt/>-->
                                    <h1>
                                        <!-- Кешбэк {{ anketa.term.valueTerm === 12 ? cashback : "0" }} -->
                                        Ваш кешбэк: {{ anketa.term.valueTerm === 1 ? "0" : cashback }}
                                        <b class='ruble-icon'>&#8381;</b>
                                    </h1>
                                </div>
                                <p>
                                    {{
                                        anketa.term.valueTerm === 1 ?
                                            "Чтобы получить кешбэк, выберите срок более 1 месяца":
                                            ""
                                    }}
                                </p>
                                <p class="pointer more" @click="$vuetify.goTo('#cashback', 500)">
                                    Подробнее
                                </p>
                            </div>

                            <v-btn
                                class='rectangle-button modal-open'
                                data-modal='form-modal'
                                id='get-money'
                                @click='changeStatusModal()'
                            >
                                Получить
                                <span id='button-value-amount'>
                  {{ anketa.conditions.initialPayment.toLocaleString().replace(/,/, " ") }}
                  <b class='ruble-icon'>&#8381;</b>
                </span>
                            </v-btn>
                        </div>

                        <div
                            class='preloader'
                            v-show='
                !showSliderComplited.status && !showSliderComplited.error.status
              '
                        >
                            <img class src='../assets/img/preloader-two.gif'/>
                        </div>
                        <div class='error-text' v-show='showSliderComplited.error.status'>
                            <p>{{ showSliderComplited.error.text }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Slider from "@/components/Slider";
import Condition from "@/components/Condition.vue";
import {moneyFormatWithSpace} from "@/components/helpers/MoneyFormat.js";

export default {
    name: "TemplateSlider",
    components: {Slider, Condition},
    props: {
        countPeriod: Number,
        reRenderComponent: Boolean,
    },
    data: () => ({
        componentKey: 0, //для принудительного повторного рендинга компонента
        showSliderComplited: {
            status: false,
            error: {
                status: false,
                text: "Системная ошибка",
            },
        },
        MinSum: 3000,
        MaxSum: 60000,
        sliderPeriod: [1],
        sliderPeriodSTR: ["1"],
        sliderinitialPayment: {
            name: "Сумма",
            unit: "&#8381;",
            useModel: true,
        },
        sliderTerm: {
            name: "Срок",
            unit: "мес.",
            useModel: false,
        },
        params: ''

    }),
    watch: {
        reRenderComponent: function () {
            this.forceRerender();
        },
    },
    methods: {
        changeStatusModal() {
            this.$root.metrika88149184.reachGoal("get_money");
            this.$root.mindbox("Выбрал сумму на калькуляторе");

            this.$root.anketa.isOpenModal = true;
        },
        forceRerender() {
            this.componentKey += 1;
        },
        limitSumDefinitions(urlParams) {
            if(urlParams.get("e")) this.$root.mindbox("Посетил страницу. Лимит 15k");
            if(urlParams.get("w")) this.$root.mindbox("Посетил страницу. Лимит 30k");
            if(urlParams.get("o")) this.$root.mindbox("Посетил страницу. Лимит 50k");
            if(urlParams.get("h")) this.$root.mindbox("Посетил страницу. Лимит 80k");
            if(urlParams.get("t")) this.$root.mindbox("Посетил страницу. Лимит 100k");
            if(urlParams.get("m")) this.$root.mindbox("Посетил страницу. Лимит 150k");
            if(urlParams.get("r")) this.$root.mindbox("Посетил страницу. Лимит 100k. NearPrime");
            if(urlParams.get("y")) this.$root.mindbox("Посетил страницу. Лимит 500k. NearPrime");
            if(urlParams.get("nr")) this.$root.mindbox("Посетил страницу. Лимит 20к. Нерезидент");
        },
        getFirstDate(urlParams) {
            this.connector
                .isMainInformationCalc(urlParams.get("s"))
                .then((response) => {
                    if (response.success && response.slider_params.maxSum !== 0) {
                        this.showSliderComplited.status = true;
                        this.anketa.firstDateSlider = this.getObjSort(response.slider_params.listStocks);

                        if (urlParams.get("ac") !== null) {
                            this.anketa.actionInfo = true;
                            this.params = 'ac'
                        }

                        else if (urlParams.get("acnew") !== null) {
                            this.anketa.actionInfo = true
                            this.params = 'acnew'
                        }
                        for (let k in this.anketa.firstDateSlider) {
                            this.anketa.firstDateSlider[k].periodStr = String(this.anketa.firstDateSlider[k].period);
                        }
                        this.sliderPeriod = this.getsliderPeriod(this.anketa.firstDateSlider);

                        this.sliderPeriodSTR = this.getsliderPeriodSTR(this.anketa.firstDateSlider);
                        this.MaxSum = response.slider_params.maxSum;
                        this.MinSum = response.slider_params.minSum;

                        if (urlParams.get("sum")) {
                            this.anketa.initialPayment.value = +urlParams.get("sum") * 1000;
                        } else {
                            this.anketa.initialPayment.value = response.slider_params.maxSum;
                        }

                        for (let i in this.sliderPeriod) {
                            if (this.sliderPeriod[i] == response.slider_params.defaultPeriod) {
                                this.anketa.term.value = parseInt(i) + 1;
                            }
                        }
                        this.anketa.conditions.calculate(this.anketa.initialPayment.value, this.anketa.term.value);
                        return this.anketa.firstDateSlider;
                    } else {
                        this.anketa.sliderParams = false;
                    }
                })
                .catch((error) => {
                    this.showSliderComplited = {
                        status: false,
                        error: {
                            status: true,
                            text: error,
                        },
                    };
                });
        },
        getObjSort(obj) {
            return obj.sort((a, b) => (a.period > b.period ? 1 : -1));
        },
        getsliderPeriod(obj) {
            let array = [];
            for (let k in obj) {
                array.push(obj[k].period);
            }
            return array;
        },
        getsliderPeriodSTR(obj) {
            let array = [];
            for (let k in obj) {
                array.push(obj[k].periodStr);
            }
            return array;
        },
    },

    computed: {
        anketa() {
            return this.$root.anketa;
        },
        connector() {
            return this.$root.connector;
        },
        cashback: {
            get() {
                return moneyFormatWithSpace((this.anketa.initialPayment.value / 100) * this.anketa.term.percent * (this.anketa.term.valueTerm - 1));
            },
        },

        minSumForDown() {
            return moneyFormatWithSpace(this.MinSum);
        },

        maxSumForDown() {
            return moneyFormatWithSpace(this.MaxSum);
        },

        minTerm: {
            get() {
                return Math.min.apply(null, this.sliderPeriod);
            },
        },
        maxTerm: {
            get() {
                return Math.max.apply(null, this.sliderPeriod);
            },
        },
        templateText: {
            set() {
                return this.anketa.conditions.initialPayment > 40000 ? false : true;
            },
            get() {
                return this.anketa.conditions.initialPayment > 40000 ? false : true;
            },
        },
    },
    created() {
        let urlParams = new URLSearchParams(window.location.search);
        this.anketa.mindboxId = urlParams.get("c");
        this.getFirstDate(urlParams);
        this.limitSumDefinitions(urlParams);
    },
};
</script>

<style lang="scss" scoped>
    .block-2 {
        // background-color: $color-12;
        background: url("../assets/img/emoji-shower.png"), rgba(255, 255, 255, 0.8);
        margin: 0 auto;
        background-blend-mode: color;
        background-repeat: no-repeat;
        background-position: top right;

    }

    .more {
        color: #eb3b66!important;
        font-weight: 500!important;
    }
    
</style>
