<template>
  <v-container>
    <div class="container_info_limit">
      <p
        :class="appType('vtb') ? 'vtb-info-limit_description' : 'container-info-limit_description'"
        >{{ nameSlider.name }}
      </p>
      <div class="container-info-limit_price">
        <p
          :class="appType('vtb') ? 'vtb-value-period' : 'skip-value-period'" 
          >
          {{ nameSlider.useModel ? sliderSum : sliderArray[sliderValue - 1] }}
          <span
            class="ruble-icon"
            v-html="nameSlider.unit"
          ></span>
        </p>
      </div>
    </div>
    <div @click="metricHandler" >
      <v-row class="text-center">
        <v-col>
          <v-slider
            v-model="sliderValue"
            :min="min"
            :max="max"
            :step="step"
            ticks
            :color="sliderColors.bg"
            :track-color="sliderColors.track"
            :tick-size="tickSize"
            :tick-labels="tickLabels">
          </v-slider>
          <slot name="labelSliderSumm"></slot>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { moneyFormatWithSpace } from "@/components/helpers/MoneyFormat.js";

export default {
  name: "Slider",
  props: {
    nameSlider: Object,
    value: Number,
    min: Number,
    max: Number,
    step: Number,
    comment: String,
    tickSize: Number,
    tickLabels: Array,
    sliderArray: Array,
    sliderColors: Object,
    metricGoalName: String
  },

  data(){
    return {
      isMetricUse: false
    }
  },
  computed: {
    sliderValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    sliderSum() {
      return moneyFormatWithSpace(this.sliderValue)
    },

    isNordwind(){
      return this.$root.state.isNordwind() 
    }
  },

  methods: {
    metricHandler(){
      if(this.metricGoalName !== undefined && !this.isMetricUse){
        if(this.isNordwind){
          console.log(this.metricGoalName)
          this.$root.metrika96799237.reachGoal(this.metricGoalName);
        }
        else if(this.appType('cash')) {
          this.$root.metrika88149184.reachGoal(this.metricGoalName);
        }
        else if(this.appType('vtb')) {
          this.$root.metrika97329470.reachGoal(this.metricGoalName);
        }
        this.isMetricUse = true
      }else {
        return
      }
    },
    appType(type){
      if(type === this.$root.state.type()){
        return true
      }else {
        return false
      }
    }


  },

  mounted(){
    console.log('slider appType', this.appType('vtb'))
  }


}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vtb-variables.scss";
.vtb {
  &-info-limit_description {
    color: #2F3441;
    @include medium-text-thin;
    text-align: initial;
    width: 50%;
  }

  &-value-period {
    @include bold-text;
    color: #2F3441;
    display: flex;
    align-items: center;
    text-align: right;
    margin: 0;
    @media (max-width: 400px) {
      font-size: 30px;
    }

    &>span {

      
      display: flex;
      align-items: center;
      text-align: right;

      padding: 0 0 0 5px;

      &.ruble-icon {
        @include bold-text;
        color: #2F3441;
        // font-weight: 900;
        transform: translateY(6.6%);
        @media (max-width: 400px) {
          font-size: 30px;
        }
      }
  }
  }
}

</style>
