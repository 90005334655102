var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth"},[_c('div',{staticClass:"main-option-for-app"},[_c('h1',{staticClass:"mb-6",class:_vm.appType('vtb') ? 'vtb_form_title' : 'forms_title'},[_vm._v(_vm._s(!_vm.showRegisterCodeField ? "До получения денег осталось заполнить два поля!" : "Подтверждение")+" ")]),_c('p',{staticClass:"mb-6 auth_subtitle text-left"},[_vm._v(_vm._s(!_vm.showRegisterCodeField ? "" : `Введите код, отправленный на номер +${_vm.anketa.phone.valueWithMask()}`)+" ")])]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[(!_vm.showRegisterCodeField)?_c('FieldsCard',{staticClass:"mb-5",attrs:{"anketaNames":['birthdate'],"altCols":{
                cols: 12,
                xl: 12,
                lg: 12,
                sm: 12,
                xs: 12,
            }}}):_vm._e(),(!_vm.showRegisterCodeField)?_c('FieldsCard',{attrs:{"anketaNames":['incomeMain']}}):_vm._e(),(_vm.showRegisterCodeField)?_c('FieldsCard',{attrs:{"anketaNames":['registerCode']}}):_vm._e(),(_vm.isSmsForm && _vm.showRegisterCodeField)?_c('div',{staticClass:"helper"},[_c('span',{staticClass:"helper-text",on:{"click":function($event){return _vm.changeTemplate(true)}}}),_c('span',{staticClass:"helper-text",on:{"click":_vm.callSopd}},[_vm._v("Отправить код повторно ")])]):_vm._e(),_c('v-btn',{staticClass:"mt-6",class:!_vm.appType('vtb') ? 'rectangle-button' : 'vtb-button',attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.auth}},[_vm._v(_vm._s(!_vm.showRegisterCodeField ? "Далее" : "Отправить заявку")+" ")])],1),(_vm.errorAuthTextApi.status && _vm.errorAuthTextApi.text !== '')?_c('div',{staticClass:"auth_error"},[_c('p',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.errorAuthTextApi.text))])]):_vm._e(),_c('InfoTooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.showErrorTooltip && !_vm.appType('vtb')),expression:"showErrorTooltip && !appType('vtb')"}],on:{"close-tooltip":_vm.closeTooltip}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.showErrorTooltip && _vm.appType('vtb')),expression:"showErrorTooltip && appType('vtb')"}],staticClass:"auth_vtb_info_error"},[_vm._v("Вы указали неверные данные. Пожалуйста, проверьте еще раз. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }