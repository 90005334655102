<template>
    <div>
        <p 
            :class="!appType('vtb') ? 'registration_subtitle' : 'vtb_subtitle'"
            class="mb-3 text-left"
            >
            Заполните анкету, при необходимости прикрепите нужные документы
        </p>
        <v-form ref="form">
            <div v-for="field in fields" :key="field.title">
                <FormBlock :fields="field.items" :title="field.title" />
            </div>

            <v-fade-transition>
                <div 
                    v-if="openSmsCode"
                        :class="appType('vtb') ? 'vtb_code-input' : 'code-input'"
                        class="d-flex align-center">
                    <FieldsCard :anketaNames="['registerCode']" />

                    <div class="text-left ml-6">
                        <h4 
                            :class="appType('vtb') ? 'vtb_block_title' : 'block-title'"
                            >Код из СМС
                        </h4>
                        <timer
                            v-if="!timerEnded"
                            label="Новый пароль можно получить через"
                            :seconds="seconds"
                            append="сек."
                            class="sopd-timer pl-0"
                            @complete="timerEnded = true"
                        />
                        <a
                            v-else
                            class="sopd-link pl-0"
                            @click.prevent="
                                smsSended = false;
                                timerEnded = false;
                                sendSms();
                            "
                            >Отправить смс повторно</a
                        >
                    </div>
                </div>
            </v-fade-transition>
        </v-form>

        <v-btn 
            @click="registration"
            :class="appType('vtb') ? 'vtb-button' : ' rectangle-button'"
            class=" mt-6"
            >
            {{ smsSended ? "Далее" : "Отправить" }}
        </v-btn>

        <p class="error-text" v-show="errorText">
            Ошибка:
            {{ typeof errorText !== "object" ? errorText : errorText.reason }}
        </p>
    </div>
</template>

<script>
import FormBlock from "@/components/controls/FormBlock";
import FieldsCard from "@/components/FieldsCard";
import Timer from "@/components/controls/Timer";

export default {
    components: { Timer, FieldsCard, FormBlock },

    data: () => ({
        fields: [
            { title: "Основное", items: ["fio", "birthdate", "income"] },
            {
                title: "Паспорт РФ",
                items: ["passport", "issueDate", "divisionCode", "issuedBy"],
            },
            {
                title: "Контакты",
                items: ["residentialAddress", "registrationAddress", "phone"],
            },
        ],
        smsSended: false,
        openSmsCode: false,
        errorText: "",

        timerEnded: false,
        seconds: 120,
    }),

    computed: {
        connector() {
            return this.$root.connector;
        },
        anketa() {
            return this.$root.anketa;
        },

        isNordwind(){
            return this.$root.state.isNordwind()
        }
    },

    methods: {
        registration() {
            if (
                this.$refs.form.validate() &&
                !this.anketa.issueDate.errorMessages
            ) {
                if (this.smsSended && this.anketa.registerCode.success) {
                    this.createUser();
                } else {
                    this.sendSms();
                }
            }
        },

        createUser() {
            const registrationAddress = {
                block: this.anketa.registrationAddress.addressInfo.block || "",
                city: this.anketa.registrationAddress.addressInfo.city !== null ? this.anketa.registrationAddress.addressInfo.city : this.anketa.registrationAddress.addressInfo.settlement_with_type,
                flat: this.anketa.registrationAddress.addressInfo.flat || "",
                house: this.anketa.registrationAddress.addressInfo.house,
                index: this.anketa.registrationAddress.addressInfo.index || "",

                region: this.anketa.registrationAddress.addressInfo.region_type_full
                    ? this.anketa.registrationAddress.addressInfo.region + " " + this.anketa.registrationAddress.addressInfo.region_type_full
                    : this.anketa.registrationAddress.addressInfo.region,
                street: this.anketa.registrationAddress.addressInfo.street !== null ? this.anketa.registrationAddress.addressInfo.street : this.anketa.registrationAddress.addressInfo.settlement_with_type,
            };

            const residenceAddress = {
                block: this.anketa.residentialAddress.addressInfo.block || "",
                city: this.anketa.residentialAddress.addressInfo.city !== null ? this.anketa.residentialAddress.addressInfo.city : this.anketa.residentialAddress.addressInfo.settlement_with_type,
                flat: this.anketa.residentialAddress.addressInfo.flat || "",
                index: this.anketa.residentialAddress.addressInfo.index || "",
                house: this.anketa.residentialAddress.addressInfo.house,
                region: this.anketa.residentialAddress.addressInfo.region_type_full
                    ? this.anketa.residentialAddress.addressInfo.region + " " + this.anketa.residentialAddress.addressInfo.region_type_full
                    : this.anketa.residentialAddress.addressInfo.region,
                street: this.anketa.residentialAddress.addressInfo.street !== null ? this.anketa.residentialAddress.addressInfo.street : this.anketa.residentialAddress.addressInfo.settlement_with_type,
            };

            let personalData = {
                birthPlace: this.anketa.residentialAddress.addressInfo.city !== null ? this.anketa.residentialAddress.addressInfo.city : this.anketa.residentialAddress.addressInfo.region_with_type,
                dateOfBirth: this.anketa.birthdate.dateValue(),
                phoneNumber: this.anketa.phone.cleanValue(),
                document: {
                    issueDate: this.anketa.issueDate.dateValue(),
                    issuer: this.anketa.issuedBy.value,
                    issuerCode: this.anketa.divisionCode.value,
                    number: this.anketa.passport.cleanValue(),
                    type: 1,
                },
                firstName: this.anketa.fio.name,
                middleName: this.anketa.fio.middleName,
                lastName: this.anketa.fio.lastName,
                incomeAmount: {
                    // value: this.anketa.initialPayment.value + "",
                    value: this.anketa.income.cleanValue() + "",
                    currency: "RUB",
                },
                residenceAddress: residenceAddress,
                registrationAddress: this.anketa.residentialAddress.checkboxValue ? residenceAddress : registrationAddress,
            };

            const createData = {
                rate_id: this.anketa.conditions.rateId,
                period: this.anketa.term.valueTerm,
                percent: this.anketa.term.percent,
                amount: this.anketa.initialPayment.value,
                // account: this.anketa.receiveOnCard.value && this.anketa.conditions.initialPayment <= 40000 ? "" : this.anketa.accountNumberBank.cleanValue(),
                account: 40811111111111111111,
                // bik: this.anketa.receiveOnCard.value && this.anketa.conditions.initialPayment <= 40000 ? "" : this.anketa.accountBank.bik,
                bik: 111111111,
                // card_number: this.anketa.receiveOnCard.value && this.anketa.conditions.initialPayment <= 40000 ? this.anketa.accountCard.cleanValue() : "",
                card_number: 1111111111111111,
                // bank_name: this.anketa.accountBank.valueAdditionalInfoBankName,
                bank_name: "Банк не найден. Проверьте правильность БИК.",
                // correspondent_account: this.anketa.accountBank.valueAdditionalInfoCorrespondentAccount,
                correspondent_account: 1111111111111111,
                merge: false,
                phone: this.anketa.phone.valueFirstDigitIs9(),
                token: localStorage.getItem('token'),
                token_dt: localStorage.getItem('token_dt'),
                personalData,
            };


            this.connector
                .isRegistrationAndCreateApplication(createData)
                .then((response) => {
                    if (response.success) {
                        this.anketa.client_id = response.new_client_id;
                        this.anketa.new_client_token = response.new_client_token;
                        this.anketa.token_timestamp = response.new_client_timestamp;
                        this.$emit("nextStep", "WaitingStatus");
                    } else {
                        this.errorText = response.message;
                    }
                })
                .catch((response) => {
                    this.errorText = response.message;
                });
        },

        sendSms() {
            if (!this.anketa.registerCode.success && !this.smsSended) {
                this.connector
                    .sopdSendWithBirthday(this.anketa.phone.valueFirstDigitIs9(), this.anketa.birthdate.dateValue())
                    .then((response) => {
                        console.log('sopdSend response', response)
                        if (response.success) {
                            this.openSmsCode = true;
                            this.smsSended = true;
                        } else {
                            this.errorText = response.message;
                        }
                    })
                    .catch((response) => {
                        this.errorText = response.message;
                    });
            } else {
                this.connector
                    .sopdVerify(
                        this.anketa.registerCode.value,
                        this.anketa.phone.valueFirstDigitIs9()
                    )
                    .then((data) => {
                        if (data.success) {
                            console.log(data)
                            localStorage.setItem('token', data.auth.token)
                            localStorage.setItem('token_dt', data.auth.token_dt)
                            this.anketa.registerCode.success = true;
                            this.registration();
                        } else {
                            this.anketa.registerCode.success = false;
                            this.errorText = data.message;
                        }
                    })
                    .catch((error) => {
                        this.anketa.registerCode.success = false;
                        this.errorText = error.message;
                    });
            }
        },

        appType(type){
            if(type === this.$root.state.type()){
                return true
            }else {
                return false
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vtb-variables.scss";

.registration_subtitle {
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #656565;
}
.vtb_subtitle{
    @include vtb-form-text;
    color: #656565;

    @media (max-width: 400px) {
        color: #2F3441;
    }
}

.vtb_block_title {
    @include vtb-form-title-paragraph;
    color:  #2F3441;
}
</style>
