<template>
  <span>
    {{ label }} {{ timerValue }} {{ append }}
  </span>
</template>

<script>
export default {
  props: {
    label: String,
    seconds: Number,
    append: String,
  },
  mounted() {
    this.timerValue = this.seconds || 0;
    this.interval = setInterval(this.decrease, 1000);
  },
  data: () => ({
    interval: null,
    timerValue: 0,
  }),
  methods: {
    decrease() {
      if (this.timerValue > 0) {
        this.timerValue--
      } else {
        clearInterval(this.interval);
        this.$emit('complete');
      }
    }
  }
}
</script>