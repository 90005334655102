<template>
    <div :class='{active: disabledPacket}' class='additional-service mb-10'>
        <div>
            <div class='gradient-border'></div>

            <div 
                v-if='openButtonMoreInsurance' 
                @click='openButtonMoreInsurance = false' 
                class='modal-info-expand'>Подробнее
                <!--                <i-->
                <!--                    :class="{ 'img-upward': openButtonMoreInsurance }"-->
                <!--                    aria-hidden='true'-->
                <!--                    class='v-icon notranslate mdi mdi-chevron-down theme&#45;&#45;light'-->
                <!--                ></i>-->
            </div>

            <div 
                v-else :class='{active: disabledPacket}'
                class='additional-service-item d-flex justify-space-between align-center'>
                <h3 
                    class='grey--text fz18'>
                    Пакет «<span 
                                :class='{"border-dashed pointer": !disabledPacket}'
                                @click='packetTooltip = !packetTooltip'
                                >Оптимальный
                            </span>»
                </h3>

                <p v-if='!disabledPacket' class='main-text font-weight-regular'>
                    <!-- временно закоментил -->
                    <!-- {{ anketa.creditInformation.insuranceData[2].cost }}&#8381;  -->
                      <!-- конец -->
                    <!-- <span class='light-grey--text font-weight-light'>/ в день</span> -->
                </p>

                <p @click='packetTooltip = !packetTooltip'
                   class='color-primary m-0 pointer border-dashed font-weight-medium' v-else>Подробнее</p>

                <!-- <PacketTooltip 
                    v-if='packetTooltip' 
                    @choiseTarif='choiseTarif' 
                    @disableTooltip='sureTooltip = true'
                    :packet='anketa.creditInformation.insuranceData[2]' 
                    v-model='packetTooltip'
                /> -->

                
                <!-- задача Pl-189 -->
                <PacketTooltip 
                    v-if='packetTooltip' 
                    @choiseTarif='choiseTarif' 
                    @disableTooltip='sureTooltip = true'
                    :packet='anketa.creditInformation' 
                    v-model='packetTooltip'
                />
                <!--  -->
                <SureTooltip 
                    v-if='sureTooltip'
                    @choiseTarif='choiseTarif' 
                    @disableTooltip='disable' 
                    :packet='anketa.creditInformation.insuranceData[2]' 
                    v-model='sureTooltip'
                />
            </div>
        </div>
    </div>
</template>

<script>
import { moneyFormatWithRegExp, moneyFormatWithSpace, moneyRounding } from "@/components/helpers/MoneyFormat";
import PacketTooltip from "@/components/controls/PacketTooltip.vue";
import SureTooltip from "@/components/controls/SureTooltip.vue";

export default {
    components: { SureTooltip, PacketTooltip },
    props: {
        loading: Boolean,
    },

    data: () => ({
        openButtonMoreInsurance: true,
        packetTooltip: false,
        sureTooltip: false,
        disabledPacket: false,
        fakeData: {
            amount: "3000.0",
            requestedSumm: "3000.0",
            currentDebtAmount: 0.0,
            creditDocuments: [
                {
                    documentName: "info_life",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                },
                {
                    documentName: "info_work",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                },
                {
                    documentName: "statement_services",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                },
                {
                    documentName: "statement_life",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                },
                {
                    documentName: "statement_work",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                },
                {
                    documentName: "statement_sms",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                },
                {
                    documentName: "insur_life",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                },
                {
                    documentName: "insur_work",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                },
                {
                    documentName: "consolidated",
                    url: "https://api-docs.paylate.ru/offer/cd543cec-0002-4535-bff3-e6818a4ebd6f",
                }
            ]
        }
    }),

    methods: {
        disable() {
            this.disabledPacket = true;
        },

        choiseTarif(id) {
            this.sendInsurancePackage(id).then(() => {
                if (id !== 0) {
                    for (let k = 0; k < this.$root.anketa.creditInformation.insuranceData.length; k++) {
                        if (this.$root.anketa.creditInformation.insuranceData[k].id === id) {
                            this.$root.anketa.creditInformation.insuranceData[k].insuranceSelected = true;
                        } else {
                            this.$root.anketa.creditInformation.insuranceData[k].insuranceSelected = false;
                        }
                    }
                } else {
                    for (let k = 0; k < this.$root.anketa.creditInformation.insuranceData.length; k++) {
                        this.$root.anketa.creditInformation.insuranceData[k].insuranceSelected = false;
                    }
                }
            });
        },

        sendInsurancePackage(id_tarif) {
            return this.$root.connector
                .isInsurancePackage(id_tarif)
                    .then((response) => {
                        if (response.success) {
                            this.disabledPacket = false;
                            console.log('additional services isInsurancePackage', response.info)
                            this.$root.anketa.creditInformation = response.info;
                        } else {
                            return false;
                        }
                    })
                    .catch((error) => {
                        this.errorAuthTextApi.status = true;
                        this.errorAuthTextApi.text = error;
                    });
                
        },


    },

    computed: {
        anketa() {
            return this.$root.anketa;
        },

        creditInfo() {
            let credits = this.$root.anketa.creditInformation;

            if (Object.entries(credits).length !== 0) {
                for (const key in credits) {
                    if (Object.hasOwnProperty.call(credits, key)) {
                        let element = credits[key];
                        if ((typeof element === "number" || typeof element === "string") &&key !== "creditDocument") {
                            credits[key] = moneyFormatWithSpace(credits[key]);
                        }
                    }
                }

                if (credits.paymentSchedule) {
                    credits.paymentSchedule.forEach((element) => {
                        element.summ = moneyFormatWithRegExp(element.summ, /\./, ",");
                    });
                    credits.insuranceData.forEach((element) => {
                        element.cost = moneyRounding(element.cost);
                    });

                    credits.period = credits.paymentSchedule.length;
                }
            }

            return credits;
        },
    },
};
</script>

<style lang='scss' scoped>

.additional-service {
    margin-top: 20px;

    &.active {
        margin-top: 8px;
    }
}

.additional-service-item {
    position: relative;
    border-radius: 12px;
    margin-top: 8px;
    padding: 12px;
    height: 49px;

    &.active {
        margin-top: 20px;
        background: #FFEEEB;
    }
}

.color-primary {
    color: var(--v-primary-base) !important;
}
</style>
