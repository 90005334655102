<template>
  <div>

    <div class="main-option-for-app">
      <h1>Заявка отклонена</h1>
      <p class="status-img mt-8"><img src="../../assets/img/pngegg.png"></p>
    </div>

  </div>
</template>

<script>
export default {
  name: "Declined",
  computed: {
    isNordwind(){
      return this.$root.state.isNordwind()
    }
  },
  methods: {
    appType(type){
      if(type === this.$root.state.type()){
          return true
      }else {
          return false
      }
    },
  },
  mounted() {
    if(this.isNordwind){
      this.$root.metrika96799237.reachGoal("reject");
    }
    else if(this.appType('cash')) {
      this.$root.metrika88149184.reachGoal("reject");
    }
    else if(this.appType('vtb')) {
      this.$root.metrika97329470.reachGoal("reject");
    }
      this.$root.mindbox("Получил отказ по заявке");
  }
}
</script>

<style scoped>

</style>
