<template>
    <div id="checkApplication">
        <h1
            :class="appType('vtb') ? 'vtb_forms_title' : 'forms_title'"
            class="mb-5"
            >Заявка ранее уже была отправлена</h1>
        <p class="mb-5">После отправки новой заявки текущая будет аннулирована, вернуться к ее оформлению?</p>
        <div
            class="account-info-well"
        >
            <table class="table-well">
                <tr v-if="anketa.creditInformation.requestedSumm">
                    <td>Сумма заявки</td>
                    <td>{{ anketa.creditInformation.requestedSumm }} &#8381;</td>
                </tr>
                <tr v-if="anketa.creditInformation.months">
                    <td>Количество месяцев</td>
                    <td>{{ anketa.creditInformation.months }}</td>
                </tr>
                <tr v-if="anketa.creditInformation.monthlyPayment">
                    <td>Расчетный ежемесячный платеж</td>
                    <td>{{ anketa.creditInformation.monthlyPayment }} &#8381;</td>
                </tr>
            </table>
        </div>

        <div class="buttons">
            <v-btn
                :loading="loading"
                :disabled="loading"
                @click="choise(false)"
                :class="!appType('vtb') ? 'rectangle-button' : 'vtb-button'"

            >Да
            </v-btn>
            <v-btn
                :loading="loading"
                :disabled="loading"
                @click="choise(true)"
                :class="!appType('vtb') ? 'rectangle-button' : 'vtb-button'"

            >Нет
            </v-btn>
        </div>

        <p
            class="error-text"
            v-show="errorAuthTextApi.status"
        >Ошибка авторизации {{ errorAuthTextApi.text }}</p>
    </div>
</template>

<script>
export default {
    computed: {
        anketa() {
            return this.$root.anketa;
        },
        connector() {
            return this.$root.connector;
        },
        isNordwind(){
           return this.$root.state.isNordwind() 
        }
    },

    data: () => ({
        errorAuthTextApi: {
            status: false,
            text: ''
        },
        loading: false
    }),
    mounted() {
        if(this.isNordwind){
            this.$root.metrika96799237.reachGoal("expectation");
        }
        else if(this.appType('cash')) {
            this.$root.metrika88149184.reachGoal("expectation");
        }
        else if(this.appType('vtb')) {
            this.$root.metrika97329470.reachGoal("expectation");
        }
        this.$root.mindbox("Проверка заявки");
    },
    methods: {
        async choise(merge) {
            if (merge) {
                // await this.connector.getTokenAccessAndRefreshWithBirthday(
                //     this.anketa.phone.valueFirstDigitIs9(), this.anketa.birthdate.value
                await this.connector.isStatusApplication()
                    .then(response => {
                    this.loading = true;
                    this.errorAuthTextApi.status = false;
                    if (response.success) {
                        // localStorage.setItem("tokenAccess", response.access);
                        // localStorage.setItem("tokenRefresh", response.refresh);
                        const createData = {
                            rate_id: this.anketa.conditions.rateId,
                            period: this.anketa.term.valueTerm,
                            percent: this.anketa.term.percent,
                            // account: this.anketa.accountNumberBank.cleanValue(),
                            // bik: this.anketa.accountBank.bik,
                            // card_number: this.anketa.accountCard.cleanValue(),
                            // bank_name: this.anketa.accountBank.valueAdditionalInfoBankName,
                            // correspondent_account: this.anketa.accountBank.valueAdditionalInfoCorrespondentAccount,

                            amount: this.anketa.initialPayment.value,
                            //account: this.anketa.receiveOnCard.value && this.anketa.conditions.initialPayment <= 40000 ? "" : this.anketa.accountNumberBank.cleanValue(),
                            account: "40811111111111111111",
                            // bik: this.anketa.receiveOnCard.value && this.anketa.conditions.initialPayment <= 40000 ? "" : this.anketa.accountBank.bik,
                            bik: "111111111",
                            // card_number: this.anketa.receiveOnCard.value && this.anketa.conditions.initialPayment <= 40000 ? this.anketa.accountCard.cleanValue() : "",
                            card_number: "1111111111111111",
                            // bank_name: this.anketa.accountBank.valueAdditionalInfoBankName,
                            bank_name: "Банк не найден. Проверьте правильность БИК.",
                            // correspondent_account: this.anketa.accountBank.valueAdditionalInfoCorrespondentAccount,
                            correspondent_account: "1111111111111111",
                    
                            income_amount: this.anketa.incomeMain.cleanValue(),
                            token: localStorage.getItem('token'),
                            token_dt: localStorage.getItem('token_dt'),
                            phone: this.anketa.phone.valueFirstDigitIs9(),
                            merge,
                        };

                        this.connector.isAuthAndCreateApplication(createData).then(() => {
                            this.connector.isStatusApplication().then((response) => {
                                
                                console.log('response check application', response)
                                this.loading = false;
                                let stepData = {
                                    success: true,
                                    prev: 'checkApplication',
                                    next: 'statusWait',
                                }

                                this.$emit('changeTemplate', stepData, false);
                            })
                        
                            .catch(error => {
                                this.loading = false;
                                this.errorAuthTextApi.status = true;
                                this.errorAuthTextApi.text = error;
                            });

                        this.loading = false;
                            let stepData = {
                                success: true,
                                prev: 'checkApplication',
                                next: 'statusWait',
                            }
                            this.$emit('changeTemplate', stepData, false);
                        });
                    } else {
                        this.errorAuthTextApi.status = true;
                        this.errorAuthTextApi.text = '';
                    }
                }).catch(error => {
                    this.loading = false;
                    this.errorAuthTextApi.status = true;
                    this.errorAuthTextApi.text = error;
                });

            } else {
                let stepData = {
                    success: true,
                    prev: 'checkApplication',
                    next: 'accessApplication',
                }

                switch (this.anketa.status) {
                    case 'needMoreData':
                        stepData.next = 'needMoreData'
                        break;

                    case 'processing':
                        stepData.next = 'statusWait'
                        break;
                }

                this.$emit('changeTemplate', stepData, false);
            }
        },

        appType(type){
            if(type === this.$root.state.type()){
                return true
            }else {
                return false
            }
        },
    },
}
</script>
