<template>
    <div>
        <div v-if="!smsSucceed">
            <div class="main-option-for-app">
                <h1 
                    :class="appType('vtb') ? 'sms-page_vtb_title' : 'sms-page_title'"
                    >Подтверждение
                </h1>
                <p 
                    class="sms-page_subtitle text-left"
                    >Для зачисления денег введите код из смс
                </p>
            </div>

            <v-form @submit.prevent class="sms-form" ref="sms">
                <div class="password-input">
                    <FieldsCard class="" :anketaNames="['codeSMS']" />
                </div>

                <timer
                    v-if="!timerEnded"
                    :key="repeatSopd"
                    label="Выслать код повторно через"
                    :seconds="seconds"
                    append="сек."
                    :class="appType('vtb') ? 'vtb_sopd-timer' : 'sopd-timer'"
                    @complete="timerEnded = true"
                />
                <a v-else class="sopd-link" @click.prevent="resendSms"
                    >Отправить смс повторно</a
                >

                <p class="error-text" v-show="errorAuthTextApi.status">
                    Ошибка:
                    {{
                        typeof errorAuthTextApi.text !== "object"
                            ? errorAuthTextApi.text
                            : errorAuthTextApi.text.reason
                    }}
                </p>
            </v-form>
        </div>

        <div class="main-option-for-app" v-else>
            <h1 
                :class="appType('vtb') ? 'sms-page_vtb_title' : 'sms-page_title'"
                >Деньги отправлены
            </h1>
            <p class="text-left sms-page_success">Мы отправили деньги на ваш счет</p>
            <a class="sms-page_done-btn" :href="this.anketa.shortLink.value">
                <img src="../../assets/img/done_btn.png" />
            </a>
        </div>
    </div>
</template>

<script>
import FieldsCard from "@/components/FieldsCard";
import Timer from "@/components/controls/Timer";

export default {
    name: "SmsPage",
    components: { FieldsCard, Timer },
    data: () => ({
        timerEnded: false,
        loading: false,
        seconds: 120,
        repeatSopd: 0,
        errorAuthTextApi: {
            status: false,
            text: "",
        },
    }),
    computed: {
        connector() {
            return this.$root.connector;
        },
        anketa() {
            return this.$root.anketa;
        },
        isNordwind(){
            return this.$root.state.isNordwind()
        },
        smsSucceed: {
            set() {
                return this.anketa.codeSMS.success;
            },
            get() {
                if (this.anketa.codeSMS.success === true) {
                    if(this.isNordwind){
                        this.$root.metrika96799237.reachGoal("entering_code_success");
                        this.$root.metrika96799237.reachGoal("sms");
                    }
                    else if(this.appType('cash')) {
                        this.$root.metrika88149184.reachGoal("entering_code_success");
                        this.$root.metrika88149184.reachGoal("sms");
                    }
                    else if(this.appType('vtb')) {
                        this.$root.metrika97329470.reachGoal("sms");
                    }
                    this.$root.mindbox("Подписал заявку");
                }
                if (this.anketa.codeSMS.success === false && this.anketa.codeSMS.errorMessage.length != 0) {
                    if(this.isNordwind){
                        this.$root.metrika96799237.reachGoal("entering_code_error");
                    }else {
                        this.$root.metrika88149184.reachGoal("entering_code_error");
                    }
                    this.showMessage();
                }
                return this.anketa.codeSMS.success;
            },
        },
    },
    methods: {
        async resendSms() {
            await this.connector
                .isRequestForSMSCode()
                .then((response) => {
                    if (response.success) {
                        this.seconds = response.time_left;
                        this.timerEnded = false;
                        this.repeatSopd++;
                    } else {
                        this.errorAuthTextApi.status = true;
                        this.errorAuthTextApi.text = response.message;
                    }
                })
                .catch((error) => {
                    this.errorAuthTextApi.status = true;
                    this.errorAuthTextApi.text = error;
                });
        },

        showMessage() {
            this.errorAuthTextApi.status = true;
            this.errorAuthTextApi.text = this.anketa.codeSMS.errorMessage;
        },
        // setTimeout( window.location.replace(response.shortlink), 35000);

        appType(type){
            if(type === this.$root.state.type()){
                return true
            }else {
                return false
            }
        },

    },
};
</script>

<style lang="scss">
@import "@/assets/scss/_vtb-variables.scss";

.sms-page {
    &_title {
        font-family: Roboto !important;
    }
    &_subtitle {
        font-family: Roboto;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.5px;
        margin: 20px 0 20px !important;
    }

    &_done-btn {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #26a212;
        padding: 16px;
        border-radius: 12px;
    }

    &_success {
        font-family: Roboto;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin: 20px 0 20px !important;
    }


    &_vtb_title {
        @include vtb-form-title;
        line-height: 28px;
        color: #2F3441;
    }

}


</style>
