// export default {
//     data() {
//         return {
//             state: {
//                 type(){
//                     let subdomain = window.location.hostname.split('.')[0]
//                     return subdomain
//                 },
//                 typeOfDev(){
//                     let params = new URLSearchParams(window.location.search)
//                     let typeOfDev = params.get('dev')
//                     return typeOfDev
//                 },
//                 isNordwind(){
//                     let isNordwind

//                     if(this.type() !== 'localhost'){
//                         console.log(this.type())
//                         if(this.type() === 'nordwind' || this.type() === 'test-nordwind'){
//                             isNordwind = true
//                         }else {
//                             isNordwind = false
//                         }
//                     }else if(this.type() === 'localhost'){
//                         if(this.typeOfDev() === 'c'){
//                             isNordwind = false
//                         }else if(this.typeOfDev() === 'n'){
//                             isNordwind = true
//                         }
//                     }
//                     return isNordwind
//                 }
//             }
//         }
//     }
// }

export default {
    data() {
        return {
            state: {
                type(){
                    let subdomain = window.location.hostname.split('.')[0]
                    let type = null
                    if(subdomain !== 'localhost'){
                        if(subdomain === 'cash' || subdomain === 'test-cash'){
                            type = 'cash'
                        }else if(subdomain === 'nordwind' || subdomain === 'test-nordwind'){
                            type = 'nordwind'
                        }else if(subdomain === 'vtb' || subdomain === 'test-vtb'){
                            type = 'vtb'
                        }
                    }else {
                        if(this.typeOfDev() === 'c'){
                            type = 'cash'
                        }else if(this.typeOfDev() === 'n'){
                            type = 'nordwind'
                        }else if(this.typeOfDev() === 'v'){
                            type = 'vtb'
                        }
                    }
                    return type
                },
                typeOfDev(){
                    let params = new URLSearchParams(window.location.search)
                    let typeOfDev = params.get('dev')
                    return typeOfDev
                },
                isNordwind(){
                    let isNordwind = null

                    if(this.type() === 'nordwind'){
                        isNordwind = true
                    }else if(this.type() !== 'nordwind'){
                        isNordwind = false
                    }
                    return isNordwind
                },

                appType(type){
                    console.log('stateeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', this.type())
                    if(type === this.type()){
                        return true
                    }else {
                        return false
                    }
                }
            }
        }
    }
}
