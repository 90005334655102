<template>
  <!--footer-->
  <footer class="footer">
    <div class="container-width">
      <p
        :class="appType('vtb') ? 'vtb_laywer-information' : 'laywer-information'" 
        >
          <sup class="other">*</sup> Здесь и далее под «Кредит» понимается заём. Заём предоставляется микрокредитной компанией ООО "МКК "Кредит Лайн",
        регистрационный номер записи в государственном реестре микрофинансовых организаций
          2120150001955 в соответствии со свидетельством ФСФР России №0002195 от 14.08.2012. <sup class="other">**</sup> Информация в соответствии с <a target="_blank" href="https://кредит-лайн.рф/docs/Information_in_accordance_with_353-FZ.pdf">п. 4 ст. 5 ФЗ N 353-ФЗ</a>. <a target="_blank" href="https://paylate.ru/o-servise/">Информация о сервисе</a>, <a target="_blank" href="https://кредит-лайн.рф/">Официальный сайт займодателя</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterHTML",
  methods: {
    appType(type){
      if(type === this.$root.state.type()){
        return true
      }else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vtb-variables.scss";

.laywer-information {
  max-width: 736px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #f5f5f5;
  margin: 0 auto;
  padding: 50px 10px 100px 10px;
}

.vtb_laywer-information {
  @include small-text-middle;
  max-width: 736px;
  text-align: center;
  color: #F5F5F5;
  margin: 0 auto;
  padding: 50px 10px 100px 10px;

  & > a {
    color: #1976D2;
  }
}
</style>
