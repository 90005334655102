<template>
  <div>
    <div v-if="!appType('vtb')" class="mb-8">
      <h4 v-if="title" class="block-title text-left mb-6">{{ title }}</h4>
      <FieldsCard :anketaNames="fields"/>
    </div>
    <div v-if="appType('vtb')" class="mb-10">
      <h4 v-if="title" class="vtb_block_title text-left mb-8">{{ title }}</h4>
      <FieldsCard :anketaNames="fields"/>
    </div>
  </div>
</template>

<script>
import FieldsCard from "@/components/FieldsCard";

export default {
  components: {FieldsCard},
  props: {
    fields: Array,
    title: String
  },

  methods: {
    appType(type){
      if(type === this.$root.state.type()){
          return true
      }else {
          return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vtb-variables.scss";

.vtb_block_title {
  @include vtb-form-title-paragraph;
  color: #2F3441;
}
</style>