<template>
    <div
        
        class="tooltip"
        >
        <div class="d-flex align-center justify-space-between">
            <h4>Вы уверены?</h4>

            <v-btn fab elevation="1" class="modal-icon">
                <img src="../../assets/img/close.svg" alt="logotype" />
            </v-btn>
        </div>

        <p class="mb-2 mt-4 text-left">
            Cтрахование помогает обезопасить себя от возможных финансовых потерь
            и непредвиденных ситуаций: смерть, потеря дохода, нетрудоспособность
            и т.д.
        </p>
        <h4 class="text-left mt-3">Рекомендуем подключить услугу!</h4>

        <p v-if="errorAuthTextApi" class="my-2 error--text">
            {{ errorAuthTextApi }}
        </p>

        <v-btn
            @click="$emit('input')"
            :class="chooseBorder"
            class="active w-100 mb-3 mt-6"
            >Далее</v-btn
        >
        <p @click="disable" class="pointer m-0 w-100 text-center">Отключить</p>
    </div>
</template>

<script>
export default {
    methods: {
        disable() {
            this.$root.connector
                .isInsurancePackage(0)
                .then((response) => {
                    if (response.success) {
                        this.$emit("input");
                        this.$emit("disableTooltip");
                        this.$root.anketa.creditInformation = response.info;
                    } else {
                        this.errorAuthTextApi = response.message;
                    }
                })
                .catch((error) => {
                    this.errorAuthTextApi = error;
                });
        },

        appType(type){
            if(type === this.$root.state.type()){
                return true
            }else {
                return false
            }
        }
    },

    data: () => ({
        errorAuthTextApi: "",
    }),
    computed: {
        chooseBorder(){
            let cssClass = null
            if(this.appType('vtb')){
                cssClass = 'vtb-border-button'
            }else if(this.appType('nordwind')) {
                cssClass = 'nordwind-border-button'
            }else if(this.appType('cash')) {
                cssClass = 'cash-border-button'
            }

            return cssClass
        },
        
    }
};
</script>

<style lang="scss" scoped>
.tooltip {
    position: absolute;
    padding: 16px;
    border: 1px solid #e9e9e9;
    border-radius: 12px;
    background-color: #fff;
    margin-bottom: 30px;
    z-index: 100;

    @media (max-width: 1250px) {
        margin-bottom: 40px;
    }
    @media (max-width: 960px) {
        margin-bottom: 80px;
    }
    @media (max-width: 600px) {
        margin-bottom: 100px;
    }
    @media (max-width: 380px) {
        margin-bottom: 140px;
    }
    @media (max-width: 350px) {
        margin-bottom: 230px;
    }

    .modal-icon {
        width: 24px !important;
        height: 24px !important;
    }
}

.cash-border-button {
    height: 42px !important;
}
.nordwind-border-button {
    height: 42px !important;
}
</style>
