<template>
  <div>
    <div v-for="field in fields" :key="field.key">
      <div v-if="field.isMobile === false ? !$root.isMobile : $root.isMobile">
        <div class="modal-screen-desc">
          <p class="desc-tooltip">{{ field.label }}</p>
          <span class="desc-tooltip text-right ml-sm-2 ml-3" v-if="checkboxText">
            <checkbox @input="changeCheckbox" :value="checkboxValue" style="white-space: nowrap"
                  :label="checkboxText"></checkbox>
          </span>
        </div>
  
        <div class="modal-screen-input">
          <v-autocomplete
            :search-input.sync="field.value"
            @update:search-input="field.input"
            @change="field.changeInput"
            no-filter
            :item-text="'value'"
            :items="field.items"
            :readonly="field.disabled"
            :placeholder="field.placeholder"
            :append-icon="''"
            solo
            flat
            :rules="field.rules"
            :icon="false"
            :no-data-text="field.placeholder"
            :outlined="field.outlined"
          ></v-autocomplete>
          <label class="is-size-5">{{ comment }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/fields/Checkbox";

export default {
  components: {Checkbox},
  props: {
    value: [Number, String, Boolean],
    itemText: String,
    fields: Array,
    items: Array,
    isMobile: Boolean,
    label: String,
    comment: String,
    type: String,
    checkboxText: String,
    change: Function,
    replaceEngToRu: {
      type: Boolean,
      default: true
    },
    checkboxValue: Boolean,
  },
  computed: {
    fieldValue: {
      get() {
        // if (this.isMoney && this.value ) {
        //     return this.value.toString().replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ');
        // } else {
        return this.value;
        // }
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    fieldItems() {
      return this.items[0]?.value !== null ? this.items : [''];
    }
  },
  
  methods: {
    changeCheckbox(val) {
      this.change(val)
    },
  }
}
</script>