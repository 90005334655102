<template>
    <div>
        <div v-if="appType('cash')">
            <div class='main-option-for-app'>
                <h1>Проверяем заявку...</h1>
                <p class="mb-6 mt-6 text-left status-waiting_subtitle">Время ожидания несколько минут</p>
            </div>
    
            <div class='preloader' v-show='waitNewStatus'>
                <img src='../../assets/img/preloader-two.gif'/>
            </div>
            <p 
                class='error-text text-left' 
                v-show='errorAuthTextApi.status'>
                {{ errorAuthTextApi.text }}
            </p>
        </div>

        <div v-if="appType('vtb')">
            <div class='main-option-for-app'>
                <h1 class="vtb_forms_title text-left">{{ !changeText.flag ? changeText.textForChange.title[0] : changeText.textForChange.title[1] }}</h1>
                <div 
                    :class="{'status-waiting_flexible_text' : changeText.flag}"
                    class="status-waiting_inline_text"
                    >
                    <p  v-show="changeText.flag === false"
                        class="text-left status-waiting_subtitle">
                        {{changeText.textForChange.subtitle[0]}}
                        <span class="status-waiting_subtitle_vtb-second">{{!isMobile ? 'Пожалуйста, не закрывайте окно.': 'Не закрывайте окно.' }}</span>
                        <!-- <span class="status-waiting_subtitle_vtb-second">'Пожалуйста, не закрывайте окно.'</span> -->
                    </p>
                    <p  v-show="changeText.flag === true"
                        class="text-left status-waiting_subtitle">
                        {{ changeText.textForChange.subtitle[1]}}
                    </p>
                    <p v-show="changeText.flag === true" class="status-waiting_subtitle_vtb-second">Пожалуйста, не закрывайте окно.</p>
                </div>
            </div>
    
            <div class='preloader' v-show='waitNewStatus'>
                <img src='../../assets/img/vtb/vtb_preloader-two.gif'/>
            </div>
            <p 
                class='error-text text-left' 
                v-show='errorAuthTextApi.status'>
                {{ errorAuthTextApi.text }}
            </p>
        </div>

        <div v-if="appType('nordwind')">
            <div class='main-option-for-app'>
                <h1 class="nordwind_forms_title text-left">{{ !changeText.flag ? changeText.textForChange.title[0] : changeText.textForChange.title[1] }}</h1>
                <div 
                    :class="{'status-waiting_flexible_text' : changeText.flag}"
                    class="status-waiting_inline_text"
                    >
                    <p  v-show="changeText.flag === false"
                        class="text-left status-waiting_subtitle">
                        {{changeText.textForChange.subtitle[0]}}
                        <span class="status-waiting_subtitle_nordwind-second">{{!isMobile ? 'Пожалуйста, не закрывайте окно.': 'Не закрывайте окно.' }}</span>
                        <!-- <span class="status-waiting_subtitle_nordwind-second">'Пожалуйста, не закрывайте окно.'</span> -->
                    </p>
                    <p  v-show="changeText.flag === true"
                        class="text-left status-waiting_subtitle">
                        {{ changeText.textForChange.subtitle[1]}}
                    </p>
                    <p v-show="changeText.flag === true" class="status-waiting_subtitle_nordwind-second">Пожалуйста, не закрывайте окно.</p>
                </div>
            </div>
    
            <div class='preloader nordwind_preloader' v-show='waitNewStatus'>
                <img src='../../assets/img/nordwind/nordwind_preloader.gif'/>
            </div>
            <p 
                class='error-text text-left' 
                v-show='errorAuthTextApi.status'>
                {{ errorAuthTextApi.text }}
            </p>
        </div>

    </div>
</template>
<script>
export default {
    name: "StatusWaiting",
    props: {
        router: [Array, Object],
        waitingData: {
            type: Object,
            default: () => {
            },
        },

        merge: Boolean,
    },
    data: () => ({
        waitNewStatus: true,
        resultStatus: {},
        dictionaryStatus: {
            //заявка в процессе обработки (промежуточный статус)
            processing: {
                nextStep: {
                    status: false,
                    nextStepName: "",
                },
            },
            //необходимо прислать дополнительные данные клиента
            needMoreData: {
                nextStep: {
                    status: true,
                    nextStepName: "needMoreData",
                },
            },
            //заявка одобрена
            approved: {
                nextStep: {
                    status: true,
                    nextStepName: "accessApplication",
                },
            },
            //заявка одобрена, у клиента есть непогашенный займ по другой заявке
            merged: {
                nextStep: {
                    status: true,
                    nextStepName: "accessApplication",
                },
            },
            //заявка отклонена или клиент отказался от займа
            declined: {
                nextStep: {
                    status: true,
                    nextStepName: "declined",
                },
            },
            // действующий (активный) займ, выданный клиенту, по которому клиент выплачивает
            signed: {},
            // клиент погасил займ (конечный статус)
            repaid: {},
            // заявка закрыта (конечный статус)
            closed: {
                nextStep: {
                    status: true,
                    nextStepName: "declined",
                },
            },
        },
        errorAuthTextApi: {
            status: false,
            text: "",
        },
        changeText: {
            //счетчик запроса status
            timePassedCounter: 0,
            // время после которого меняется текст 1 === 1 сек.
            timePassedLimit: 119,
            flag: false,
            textForChange: {
                title: ['Проверяем заявку', 'Заявка в обработке - скоро все будет готово!'],
                subtitle: ['Время ожидания несколько минут.', 'В случае необходимости с вами свяжется оператор для уточнения данных. Благодарим за терпение!'],
                // text: ['Пожалуйста, не закрывайте окно.']
            },
        },
    }),
    computed: {
        connector() {
            return this.$root.connector;
        },
        anketa() {
            return this.$root.anketa;
        },

        waitng() {
            return this.router.statusWait;
        },

        isNordwind(){
            return this.$root.state.isNordwind()
        },
        isMobile(){
            console.log('status-waiting', this.$vuetify.breakpoint.xs)
            return this.$vuetify.breakpoint.xs
            
        }
    },

    mounted() {

        if(this.isNordwind){
            this.$root.metrika96799237.reachGoal("expectation");
        }
        else if(this.appType('cash')) {
            this.$root.metrika88149184.reachGoal("expectation");
        }
        else if(this.appType('vtb')) {
            this.$root.metrika97329470.reachGoal("expectation");
        }
        this.$root.mindbox("Проверка заявки");

        if (this.anketa.withoutToken && !Object?.keys(this.waitingData)?.length) {
            this.getNewStatus();
        } else {
            if (this.waitingData && Object?.keys(this.waitingData)?.length) {
                this.sendDocuments(this.waitingData);
            } else {
                this.checkStatus();
            }
        }

        this.isMobile
    },

    methods: {
        openNextStep(template) {
            let data = {
                prevPage: "statusWait",
                nextPage: template,
            };
            console.log(data);
            this.$emit("changeTemplate", data);
        },
        checkStatus() {
            this.errorAuthTextApi.status = false;
            this.resultStatus = setInterval(() => {

                if (this.waitingData && Object?.keys(this.waitingData)?.length) {
                    this.getNewStatus();
                }
                
                this.changeText.timePassedCounter++
                if(this.changeText.timePassedCounter % 7 === 0){
                    this.getNewStatus();

                }
                
            }, 1000);
        },
        getNewStatus() {
            this.connector.isStatusApplication().then((response) => {
                console.log('isStatusApplication', response)
                if (response.success) {
                        if(response.status === 'processing'){
                            console.log('changeText.timePassedCounter', this.changeText.timePassedCounter)
                            // this.changeText.timePassedCounter++
                            if(this.changeText.timePassedCounter >= this.changeText.timePassedLimit){
                                this.changeText.flag = true
                            }
                        }

                    // }),1000
                        this.anketa.status = response.status;

                        for (const k in this.dictionaryStatus) {
                            if (k == response.status) {
                                if (this.dictionaryStatus[k].nextStep.status) {
                                    console.log(`open new ${k}`);

                                    if (response.status == "needMoreData") {
                                        this.anketa.additionalData = response.info.required;
                                    } else if (response.status == "approved" || response.status == "merged") {
                                        console.log(`step ${response.status}`);
                                        this.anketa.creditInformation = response.info;
                                        for (let k = 0; k < this.anketa.creditInformation.insuranceData.length; k++) {
                                            if (this.anketa.creditInformation.insuranceData[k].id === this.anketa.creditInformation.insuranceSelected) {
                                                this.anketa.creditInformation.insuranceData[k].insuranceSelected = true;
                                            } else {
                                                this.anketa.creditInformation.insuranceData[k].insuranceSelected = false;
                                            }
                                        }
                                        this.changeText.timePassedCounter = 0
                                    }
                                    // temp
                                    this.openNextStep(this.dictionaryStatus[k].nextStep.nextStepName);
                                    this.resultStatus = clearInterval(this.resultStatus,);
                                } else {
                                    console.log("again");
                                }
                            }
                        }
                    
                } else {
                    this.resultStatus = clearInterval(this.resultStatus);

                    return false;
                }
            });
        },
        sendDocuments(data) {
            this.connector.saveDocuments(data)
                .then((response) => {
                    if (response.success) {
                        this.checkStatus();
                    } else {
                        this.errorAuthTextApi.text = response.message;
                    }
                })
                .catch((response) => {
                    console.log(response.config,response.request,response.response);
                    this.errorAuthTextApi.text = response.message;
                })
                .finally(() => (this.loading = false));
        },

        appType(type){
            if(type === this.$root.state.type()){
                return true
            }else {
                return false
            }
        },

    },
};
</script>

<style lang='scss' scoped>
.loading-button {
    width: 100%;
    height: 60px;
    background: #b32f50 !important;
    border-radius: 12px;

    img {
        width: 25px;
        height: 25px;
    }
}

@keyframes rotateLoader {
    0% {
        transform: rotate(0deg) matrix(0, 1, 1, 0, 0, 0);
    }

    100% {
        transform: rotate(360deg) matrix(0, 1, 1, 0, 0, 0);
    }
}

.status-waiting {
    &_subtitle {
        color: #656565 !important;
        display: inline;
        text-align: left;

        &_vtb{
            &-second {
                color: #1976D2;
                display: inline;
                text-align: left;
            }

        }
        &_nordwind{
            &-second {
                margin-top: 20px;
                color: #D6181E;
                display: inline;
                text-align: left;
            }

        }
    }

    &_inline_text {
        margin: 24px 0 !important;
        text-align: left !important;
    }

    &_flexible_text {
        display: flex;
        align-items: start;
        flex-direction: column;

    }

}

.text-left {
    align-items: start;
}

// .nordwind_preloader {
//     filter: hue-rotate(30deg);
// }
</style>
