<template>
    <div class="registration">
        <div v-if="!appType('vtb')">
            <div v-if="activeForm !== 'WaitingStatus'">
                <div
                    class="registration__header d-flex align-center justify-space-between"
                >
                    <div>
                        <h3 class="form-title text-left">Регистрация клиента</h3>
                        <p class="main-text neris mt-2">
                            Кредит наличными на любые цели*
                        </p>
                    </div>
    
                    <div v-if="!$root.isSizeAndDown('sm')" class="registration__summ ml-3">
                        {{ anketa.initialPayment.value.toLocaleString("ru-RU") }}
                        <span>&#8381;</span>
                    </div>
                </div>
                <div v-if="$root.isSizeAndDown('sm')" class="registration__summ">
                    {{ anketa.initialPayment.value.toLocaleString("ru-RU") }}
                    <span>&#8381;</span>
                </div>
            </div>
            <div class="registration__body mt-5">
                <component
                    :waitingData="waitingData"
                    :is="forms[activeForm]"
                    :activeForm="activeForm"
                    @changeTemplate="changeTemplate"
                    @nextStep="nextStep"
                />
            </div>
    
            <v-expansion-panels
                v-if="activeForm !== 'WaitingStatus'"
                :accordion="true"
                :flat="true"
                class="registration__panel"
            >
                <v-expansion-panel >
                    <v-expansion-panel-header>
                        <p class="second-text text-center">
                            Нажимая кнопку «Далее», вы принимаете условия настоящего
                            Соглашения на обработку персональных данных.
                        </p>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <h5 class="text-left mt-4 mt4">Ознакомьтесь с условиями предоставления сервиса</h5>
    
                        <p class="text-left mini-text mt-3">
                            Вы можете скачать файлы на свой компьютер
                        </p>
    
                        <ul class="panel-list">
                            <li class="panel-list_item">
                                <a
                                    target="_blank"
                                    href="https://paylate.ru/wp-content/uploads/2024/03/template-of-individual-terms-and-conditions-application-offer-consents-creditline.pdf"
                                    >Согласие на обработку персональных данных и
                                    получение кредитного отчета</a
                                >
                            </li>
                            <li class="panel-list_item">
                                <a
                                    target="_blank"
                                    href="https://paylate.ru/wp-content/uploads/2024/03/general-terms-and-conditions-of-the-loan-agreement-creditline.pdf"
                                    >Договор (общие условия) о предоставлении
                                    займа</a
                                >
                            </li>
                            <li class="panel-list_item">
                                <a
                                    target="_blank"
                                    href="https://paylate.ru/wp-content/uploads/2024/03/rules-for-granting-loans-creditline.pdf"
                                    >Правила предоставления займов</a
                                >
                            </li>
                            <li class="panel-list_item">
                                <a
                                    target="_blank"
                                    href="https://paylate.ru/wp-content/uploads/2024/03/agreement-on-the-use-of-a-simple-electronic-signature-creditline.pdf"
                                    >Соглашение об использовании ПЭП</a
                                >
                            </li>
                        </ul>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <div v-if="appType('vtb')">
            <div v-if="activeForm !== 'WaitingStatus'">
                <div
                    class="registration__header d-flex align-center justify-space-between"
                    >
                    <div>
                        <h3 class="vtb_form-title text-left">Регистрация клиента</h3>
                        <p class="vtb_form-subtitle mt-2">
                            Кредит наличными на любые цели*
                        </p>
                    </div>
    
                    <div v-if="!$root.isSizeAndDown('sm')" class="vtb_registration__summ ml-3">
                        {{ anketa.initialPayment.value.toLocaleString("ru-RU") }}
                        <span>&#8381;</span>
                    </div>
                </div>
                <div v-if="$root.isSizeAndDown('sm')" class="vtb_registration__summ vtb_registration__sum_mobile mt-8 text-left">
                    {{ anketa.initialPayment.value.toLocaleString("ru-RU") }}
                    <span>&#8381;</span>
                </div>
            </div>
            <div class="registration__body mt-5">
                <component
                    :waitingData="waitingData"
                    :is="forms[activeForm]"
                    :activeForm="activeForm"
                    @changeTemplate="changeTemplate"
                    @nextStep="nextStep"
                />
            </div>
    
            <v-expansion-panels
                v-if="activeForm !== 'WaitingStatus'"
                :accordion="true"
                :flat="true"

                class="registration__panel"
                >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <p class="vtb_registration_bottom_text second-text text-center">
                            Нажимая кнопку «Далее», вы принимаете условия настоящего
                            <a class="vtb_link" >Соглашения</a> на обработку персональных данных.
                        </p>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <h5 class="text-left mt-4 mt4">Ознакомьтесь с условиями предоставления сервиса</h5>
    
                        <p class="text-left mini-text mt-3">
                            Вы можете скачать файлы на свой компьютер
                        </p>
    
                        <ul class="vtb_panel-list">
                            <li class="vtb_panel-list_item">
                                <a
                                    target="_blank"
                                    href="https://paylate.ru/wp-content/uploads/2024/03/template-of-individual-terms-and-conditions-application-offer-consents-creditline.pdf"
                                    >Согласие на обработку персональных данных и
                                    получение кредитного отчета</a
                                >
                            </li>
                            <li class="vtb_panel-list_item">
                                <a
                                    target="_blank"
                                    href="https://paylate.ru/wp-content/uploads/2024/03/general-terms-and-conditions-of-the-loan-agreement-creditline.pdf"
                                    >Договор (общие условия) о предоставлении
                                    займа</a
                                >
                            </li>
                            <li class="vtb_panel-list_item">
                                <a
                                    target="_blank"
                                    href="https://paylate.ru/wp-content/uploads/2024/03/rules-for-granting-loans-creditline.pdf"
                                    >Правила предоставления займов</a
                                >
                            </li>
                            <li class="vtb_panel-list_item">
                                <a
                                    target="_blank"
                                    href="https://paylate.ru/wp-content/uploads/2024/03/agreement-on-the-use-of-a-simple-electronic-signature-creditline.pdf"
                                    >Соглашение об использовании ПЭП</a
                                >
                            </li>
                        </ul>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <div v-if="appType('vtb') && activeForm === 'MainRegistration'" class="vtb_registration_footer">
                <p class="vtb_registration_footer_text">Возможно, нам потребуется дополнительная информация.</p>
            </div>
        </div>
    </div>
</template>

<script>
import MainRegistration from "@/components/modal/registration/forms/MainRegistration";
import AdditionalInfo from "@/components/modal/registration/forms/AdditionalInfo";
import WaitingStatus from "@/components/modal/registration/forms/WaitingStatus";

export default {
    name: "registration",

    data: () => ({
        activeForm: "MainRegistration",
        waitingData: {},

        forms: {
            MainRegistration,
            WaitingStatus,
            AdditionalInfo,
        },
    }),
    computed: {
        anketa() {
            return this.$root.anketa;
        },
    },

    methods: {
        nextStep(val, data) {
            this.activeForm = val;

            this.waitingData = data;
        },

        changeTemplate(next) {
            const data = {
                success: true,
                prev: "registration",
                next: next,
            };

            this.$emit("changeTemplate", data);
        },

        appType(type){
            if(type === this.$root.state.type()){
                return true
            }else {
                return false
            }
        },

        mounted(){
            // console.log('registration getAppSize', this.$root.getAppSize('sm'))
        }
    },

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vtb-variables.scss";

.registration__panel {
        margin-top: 20px;
        .v-expansion-panel-header {
            margin-top: 0 !important;
            min-height: 0 !important;
            border: none;
            box-shadow: none;
        }

        p {
            margin: 0;
            font-family: "Roboto";
        }

        .v-expansion-panel-content__wrap {
            background: #fcfcfc;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            border-radius: 12px;
            padding: 24px 24px 40px 20px;
            margin-top: 18px;
            text-align: left;

            h5 {
                font-family: Roboto;
                font-weight: 400;
                font-size: 14px;
                line-height: 1.3em;

                color: #0a0a0d;
            }
        }

        .panel-list {
            display: grid;
            grid-template-columns: 45% 45%;
            justify-content: space-between;
            padding-left: 16px !important;
            list-style: none;

            &_item {
                font-family: "Roboto";
                font-weight: 300;
                font-size: 12px;
                line-height: 1.3em !important;
                position: relative;
                padding-left: 36px;
                margin-top: 18px;

                &:before {
                    content: "";
                    position: absolute;
                    width: 22px;
                    height: 17px;
                    left: 0;
                    top: 1px;

                    // background-image: url(../img/file-wordlist-item.svg);
                    background-image: url(../../../assets/img/file-wordlist-item.svg);
                    background-repeat: no-repeat;
                }

                a {
                    text-decoration: none;
                    color: #656565;
                }
            }
        }
    }


.vtb {
    &_form {
        &-title {
            @include vtb-form-title;
            color: #2F3441;
        }

        &-subtitle {
            @include vtb-form-subtitle ;
            color: #2F3441;
        }
    }

    &_registration__summ {
        @include vtb-form-sum;
        color: #2F3441;

        
    }

    &_panel-list {
        display: grid;
        grid-template-columns: 45% 45%;
        justify-content: space-between;
        padding-left: 16px !important;
        list-style: none;

        &_item {
            font-family: "Roboto";
            font-weight: 300;
            font-size: 12px;
            line-height: 1.3em !important;
            position: relative;
            padding-left: 36px;
            margin-top: 18px;

            &:before {
                content: "";
                position: absolute;
                width: 22px;
                height: 17px;
                left: 0;
                top: 1px;

                // background-image: url(../img/file-wordlist-item.svg);
                background-image: url(../../../assets/img/vtb/vtb_file-word.svg);
                background-repeat: no-repeat;
            }

            a {
                text-decoration: none;
                color: #656565;
            }
        }
    }

    &_registration_bottom_text {
        @include small-text;
        font-size: 13px !important;
        line-height: 16px !important;
        letter-spacing: -0.5px !important;
        text-decoration: none;

    }

    &_registration_footer {
        width: 120%;
        height: 64px;
        background-color: #E7E7E7;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 50px -32px -32px;

        &_text {
            @include vtb-form-text;
            font-weight: 300;
            font-size: 13px;
            color: #2F3441;
            padding-top: 10px;
        }
    }

    &_link {
        color: #1976D2;
    }

}
</style>
