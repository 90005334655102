<template>
    <div>
        <div class="modal-screen-desc justify-start">
            <p class="desc-tooltip">{{ label }}</p>
            <span
                class="desc-tooltip text-right ml-sm-4 ml-3"
                v-if="checkboxText"
                >
                <checkbox
                    @input="changeCheckbox"
                    :value="checkboxValue"
                    style="white-space: nowrap"
                    :label="checkboxText"
                ></checkbox>
            </span>
        </div>

        <div class="modal-screen-input autocomplete-input">
            <v-text-field
                v-model="fieldValue"
                autocomplete="false"
                :placeholder="placeholder"
                :type="type"
                solo
                flat
                :outlined="outlined"
                :hide-details="false"
                background-color="white"
                @focus="isFocus = true"
                @blur="isFocus = false"
                :errorMessages="errorMessages"
                :rules="rules"
            >
                <template #append-outer>
                    <v-slide-y-transition hide-on-leave>
                        <div
                            v-if="(isFocus && fieldItems.length > 0) || isMouseOver"
                            @mouseover="isMouseOver = true"
                            @mouseleave="isMouseOver = false"
                            class="v-menu__content theme--light custom-menu"
                        >
                            <div class="v-list">
                                <v-btn
                                    block
                                    text
                                    v-for="(item, index) in fieldItems"
                                    :key="index"
                                    @click="changeItem(item.value)"
                                    class="v-list-item v-list-item--link theme--light"
                                    :class="activeItem(item.value) ? 'primary--text v-list-item--active' : '' "
                                    >
                                    <div class="v-list-item__title">
                                        {{ item.value }}
                                    </div>
                                </v-btn>
                            </div>
                        </div>
                    </v-slide-y-transition>
                </template>
            </v-text-field>

            <label class="is-size-5">{{ comment }}</label>
        </div>
    </div>
</template>

<script>
// import Checkbox from "@/components/fields/Checkbox";
import Checkbox from "@/components/fields/Checkbox";

export default {
    components: { Checkbox },
    // components: {Checkbox},
    props: {
        value: [Number, String, Boolean],
        itemText: String,
        items: Array,
        label: String,
        comment: String,
        // append: String,
        // mask: String,
        type: String,
        checkboxText: String,
        change: Function,
        rules: Array,
        placeholder: String,
        errorMessages: String,
        outlined: Boolean,
        // {
        //     type: Boolean,
        //     default: false
        // },
        disabled: Boolean,
        replaceEngToRu: {
            type: Boolean,
            default: true,
        },
        checkboxValue: Boolean,
        // Ограничения ввода:
        isMoney: Boolean,
        selected: {
            type: Boolean
        },
        isCyrilic: Boolean,
        isCyrilicIssued: Boolean,
        isCyrilicPlace: Boolean,
        isEngEmail: Boolean,
    },
    computed: {
        fieldValue: {
            get() {
                // if (this.isMoney && this.value ) {
                //     return this.value.toString().replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ');
                // } else {
                return this.value || "";
                // }
            },
            set(value) {
                if(this.selected !== undefined) {
                    this.$emit("select", false);
                }
                this.$emit("input", value);
            },
        },

        fieldItems() {
            let value = this.items.filter((item, i) => !(item.value === this.fieldValue && i > 0));

            return this.items[0]?.value ? value : [];
        },
    },

    data: () => ({
        isFocus: false,
        isMouseOver: false,
    }),

    methods: {
        changeCheckbox(val) {
            this.change(val);
        },

        changeItem(val) {
            this.fieldValue = val;
            this.isFocus = false;
            this.isMouseOver = false;

            if(this.selected !== undefined) {
                this.$emit("select", true);
            }
        },

        activeItem(val) {
            if (val === this.fieldValue) {
                return true;
            }
        },
    },
};
</script>
