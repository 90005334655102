import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import state from '../mixins/state';
// import state from '../mixins/state';
Vue.use(Vuetify);
console.log('here state', state.data().state.type())
// let mainColor = null
let typeOfApp = state.data().state.type()
console.log('typeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', typeOfApp)
function getThemeByType(){
    let theme = null
    if(typeOfApp === 'nordwind'){
        theme = { 
            primary: '#AB292D',
            secondary: 'D6181E'
        }   
    }else if(typeOfApp === 'vtb') {
        theme = {
            // primary: `linear-gradient(90deg, rgba(0,62,255,1) 0%, rgba(0,133,255,1) 100%)`
            primary: '#003EFF',
            secondary: '#232229'
        }
    }else{
        theme = {
            
            primary: '#EB3B66'
        }
    }
    return theme

}
console.log(getThemeByType())


// background: rgb(0,62,255);
// background: linear-gradient(90deg, rgba(0,62,255,1) 0%, rgba(0,133,255,1) 100%);
export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: getThemeByType()
            // light: {
            //     // paylate
            //     // 'paylate-red': '#ef3b39',
            //     // 'pl-black': '#292929',
            //     // 'pl-red': '#ef3b39',
            //     // 'pl-green': '#00d468',
                
            //     // temp
            //     // 'light-grey': '#ABABAB',
            //     // 'grey': '#848485',
            //     // pink: '#EB3B66',
        
            //     // 'paylate-grey': '#727272',
            //     // 'paylate-green': '#00d468',
            //     // cash: '#EB3B66',
            //     // nordwind: '#AB292D',
            //     // primary: mainColor
            //     primary: getThemeByType().primary
            //     // primary: ''
            //     // global
            //     // primary: '#0066b2',
            //     // secondary: '#FFF',
            //     // error: '#F6650A',
            //     // warning: '#FFB200',
            //     // textPrimary: '#19232D',
            //     // textSecondary: '#A0A8AA',

            // },
            // },
        },
    },
});
