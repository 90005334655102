import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import anketa from './mixins/anketa'
import connector from "./mixins/connector";
import state from "./mixins/state";

Vue.config.productionTip = false
import VueMask from 'v-mask'


Vue.use(VueMask, {
	placeholders: {
		// N: null
		S: /^[а-яА-ЯёЁa-zA-Z0-9]+$/,
		Я: /[\wа-яА-Я]/
	}
});

new Vue({
	vuetify,
	mixins: [anketa, connector, state],
	render: h => h(App),
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.xs;
		},
	},

	beforeCreate() {
		let subdomain = window.location.hostname.split('.')[0]
		console.log(subdomain)
		if (process.env.NODE_ENV === 'production') {
			(function (m, e, t, r, i, k, a) {
				m[i] = m[i] || function () {
					(m[i].a = m[i].a || []).push(arguments)
				};
				m[i].l = 1 * new Date();
				k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
			})(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

			if(subdomain === 'cash' || subdomain === 'test-cash'){
				window.ym(88149184, "init", {
					clickmap: true,
					trackLinks: true,
					accurateTrackBounce: true,
					triggerEvent: true,
					webvisor: true
				});
	
				document.addEventListener('yacounter88149184inited', () => {
					this.metrika88149184 = window.yaCounter88149184;
				})
			}else if(subdomain === 'nordwind' || subdomain === 'test-nordwind'){
				window.ym(96799237, "init", {
					clickmap: true,
					trackLinks: true,
					accurateTrackBounce: true,
					triggerEvent: true,
					webvisor: true
				});

				document.addEventListener('yacounter96799237inited', () => {
					this.metrika96799237 = window.yaCounter96799237;
				})
			} else if(subdomain === 'vtb' || subdomain === 'test-vtb'){
				window.ym(97329470, "init", {
					clickmap: true,
					trackLinks: true,
					accurateTrackBounce: true,
					webvisor: true,
					triggerEvent: true
				});

				document.addEventListener('yacounter97329470inited', () => {
					this.metrika97329470 = window.yaCounter97329470;
				})
			}
		}
	},

	mounted(){
		this.getSessionId()
	},

	data: () => ({
		metrika88149184: {
			reachGoal: () => {
			}
		},
		metrika96799237: {
			reachGoal: () => {
			}
		},
		metrika97329470: {
			reachGoal: () => {
			}
		},

        mindbox(value) {
            return window.mindbox("async", {
                operation: "CashPaylateMetrika",
                data: {
                    customerAction: {
                        customFields: {
                            goalsYandexMetrica: value
                        }
                    },
                    customer: {
                        ids: {
                            clientID: this.anketa.mindboxId
                        }
                    }
                },
                onSuccess: function() { },
                onError: function(error) {
                    console.log('error')
                    console.log(error)
                }
            });
        },
		juicySession: '',
		
	}),

	methods: {
		isSizeAndDown(size){
			let condition = this.$vuetify.breakpoint[`${size}AndDown`]
			console.log(condition)
			return condition ? true : false
		},

		getSessionId(){
			window.addEventListener('sessionready', (e) => {
				this.juicySession = e.detail.sessionId
				}, false
			);
		}
	},
}).$mount('#app')

