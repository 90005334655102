<template>
    <div>
        <div class="acccess-application">
            <div class="main-option-for-app">
                <h1
                    :class="appType('vtb') ? 'vtb_forms_title' : 'forms_title'"
                    class="mb-4"
                    >
                    <img src="../../assets/img/icon4.png"/> Заявка одобрена!
                </h1>

                <!--        <p v-if="this.anketa.creditInformation.approvedAmountDifference">Сумма заявки была изменена</p>-->
            </div>

            <v-snackbar
                :value="creditInfo.approvedAmountDifference"
                color="#26a212"
                timeout="6000"
                top
            >
                <p>
                    Сумма Вашей заявки была изменена. <br/>
                    Новая сумма {{ creditInfo.requestedSumm }} &#8381;
                </p>

                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="creditInfo.approvedAmountDifference = false;
                                forceRerender();
                        "
                        >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>

            <div class="account-info-well">
                <table class="table-well">
                    <tr>
                        <td>Сумма заявки</td>
                        <td>
                            <b>{{ creditInfo.requestedSumm }} &#8381;</b>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td>Размер займа</td>
                        <td>{{ creditInfo.amount }} &#8381;</td>
                    </tr> -->
                    <tr>
                        <td>Срок займа</td>
                        <td>{{ creditInfo.period }} мес.</td>
                    </tr>
                    <tr v-if="creditInfo.currentDebtAmount != 0">
                        <td>Остаток по текущему займу</td>
                        <td>{{ creditInfo.currentDebtAmount }} &#8381;</td>
                    </tr>
                </table>
            </div>
            <div class="promocode_container mt-8">
                <v-form @submit.prevent ref="form">

                    <FieldsCard

                        ref="promocodeRef"
                        class="promocode_input"
                        :anketaNames="['promocode']"
                    />
                    
                </v-form>
                <!-- <div
                    v-if="!anketa.promocode.onFocus && anketa.promocode.value === ''"
                    @click="promocodeInfoHandler"
                    class="promocode_info"
                    >
                        <img 
                            v-if="appType('cash') && anketa.promocode.showPromocodeLogo"
                            src="../../assets/img/promocode/promocode_img_cash.png"
                            />
                        <img
                            v-if="appType('nordwind') && anketa.promocode.showPromocodeLogo" 
                            src="../../assets/img/promocode/promocode_img_nordwind.png"
                            />
                        <img
                            v-if="appType('vtb') && anketa.promocode.showPromocodeLogo" 
                            src="../../assets/img/promocode/promocode_img_vtb.png"
                            />
                        <span 
                            v-if="!anketa.promocode.promocodeOptions.error"
                            >{{ promocodeInfo }}
                        </span>
                        <span 
                            v-if="anketa.promocode.promocodeOptions.error"
                            @click="errorTextHandler"
                            class="promocode_error"
                            >Неверный промокод
                        </span>
                </div> -->
                <v-btn
                    v-if="anketa.promocode.promocodeOptions.sendRequest"
                    @click="sendPromoVerify"
                    fab
                    elevation="0"
                    class="promocode_button"
                    >
                    <img src="../../assets/img/promocode/promocode_arrow.svg">
                </v-btn>
                <v-btn
                    v-if="anketa.promocode.promocodeOptions.ok"
                    fab
                    elevation="0"
                    
                    class="promocode_button"
                    >
                    <img src="../../assets/img/promocode/promocode_ok.svg">
                </v-btn>
                <v-btn
                    v-if="anketa.promocode.promocodeOptions.error"
                    @click="clearPromoField"
                    fab
                    elevation="0"
                    
                    class="promocode_button"
                    >
                    <img src="../../assets/img/promocode/promocode_cross.svg">
                </v-btn>
            </div>
            <h2 class="mb-4">График платежей</h2>
            <div class="table-schedule">
                <div class="table-block">
                    <p class="table-block-item">Дата платежа</p>
                    <p class="table-block-item">Сумма</p>
                </div>

                <div
                    v-for="(schedule) in creditInfo.paymentSchedule"
                    :key="schedule.date"
                >
                    <div class="table-block">
                        <p class="table-block-item">{{ schedule.date | formatDate }}</p>
                        <p class="table-block-item">- {{ schedule.summ }} &#8381;</p>
                    </div>
                </div>
            </div>

            <AdditionalServices
                @choiseTarif="choosenTariff"
                @refusalService="choiseRefusalService"
                :loading="loading"
                :deleteInsurancePackage="deleteInsurancePackage"
            />

            <a
                class="download-documents"
                :href="getLink"
                target="_blank"
                >Скачать договор
            </a>
            
            <a
                v-if="!appType('vtb')"
                class="download-documents"
                href="https://paylate.ru/o-servise/"
                target="_blank"
                >О сервисе
            </a>

            <div class="fixed-button">
                <v-btn
                    :class="appType('vtb') ? 'vtb-button' : 'rectangle-button modal-button-accept'"
                    @click="sendRequestForSMSCode()"
                    >
                    <div v-if="this.anketa.promocode.promocodeOptions.ok === false">
                        Получить
                        <span class="ml-3">
                            {{ anketa.creditInformation.requestedSumm }}
                            <b class="ruble-icon">&#8381;</b>
                        </span>
                    </div>
                    <div 
                        v-if="this.anketa.promocode.promocodeOptions.ok === true"
                        class="promocode_next-step_button"
                        >Подтвердить и получить
                        <span class="ml-3">
                            {{ anketa.creditInformation.requestedSumm }}
                            <b class="ruble-icon">&#8381;</b>
                        </span>
                    </div>
                </v-btn>
            </div>
            <p 
                v-show="errorAuthTextApi.status"
                ref="errorInfo" 
                class="error-text"
                >Ошибка {{ errorAuthTextApi.text }}
            </p>
        </div>
    </div>
</template>

<script>
import AdditionalServices from "@/components/AdditionalServices.vue";
import FieldsCard from "@/components/FieldsCard"
import {
    moneyFormatWithRegExp,
    moneyFormatWithSpace,
    moneyRounding,
} from "@/components/helpers/MoneyFormat";

export default {
    components: {AdditionalServices,FieldsCard},
    name: "AccessApplication",
    data: () => ({
        openButtonMoreInsurance: false,
        componentKey: 0,
        loading: false,
        deleteInsurancePackage: false,
        errorAuthTextApi: {
            status: false,
            text: "",
        },
        showPromocodeButton: true,
        
    }),

    computed: {
        connector() {
            return this.$root.connector;
        },
        anketa() {
            return this.$root.anketa;
        },
        windowWidth() {
            return this.$root.anketa.windowWidth;
        },

        creditInfo() {
            let credits = this.$root.anketa.creditInformation;
            console.log(credits)
            if (Object.entries(credits).length !== 0) {
                for (const key in credits) {
                    if (Object.hasOwnProperty.call(credits, key)) {
                        let element = credits[key];
                        if (
                            (typeof element === "number" || typeof element === "string") &&
                            key !== "creditDocument"
                        ) {
                            credits[key] = moneyFormatWithSpace(credits[key]);
                        }
                    }
                }

                if (credits.paymentSchedule) {
                    credits.paymentSchedule.forEach((element) => {
                        element.summ = moneyFormatWithRegExp(element.summ, /\./, ",");
                    });
                    credits.insuranceData.forEach((element) => {
                        element.cost = moneyRounding(element.cost);
                    });

                    credits.period = credits.paymentSchedule.length;
                }
            }

            return credits;
        },

        isNordwind(){
            return this.$root.state.isNordwind()
        },

        promoFieldBorder(){
            let borderColor = null
            let promoError = this.anketa.promocode.promocodeOptions.error
            console.log('promocode error', promoError)
            if(this.appType('cash') && promoError){
                borderColor = '#EF4058'

            }
            else if(this.appType('nordwind') && promoError) {
                borderColor = '#D6181E'

            }
            else if(this.appType('vtb') && promoError) {
                borderColor = '#D6181E'
            }
            else {
                borderColor = '#E7E7E7'
            }
            return borderColor
        },

        promoFieldColor(){
            let borderColor = null
            let promoError = this.anketa.promocode.promocodeOptions.error
            console.log('promocode error', promoError)
            if(this.appType('cash') && promoError){
                borderColor = '#EF4058'

            }
            else if(this.appType('nordwind') && promoError) {
                borderColor = '#D6181E'

            }
            else if(this.appType('vtb') && promoError) {
                borderColor = '#D6181E'
            }
            else {
                borderColor = '#0A0A0D'
            }

            console.log('promo Color', borderColor)
            return borderColor
        },

        getLink(){
            // let link = null
            let documents = null
            documents = this.anketa.creditInformation.creditDocument.find(el => el.documentName === 'consolidated')
            console.log('AccessApplication documents', documents)
            return documents.url
        }
    },
    filters: {
        formatDate: (d) => d.substr(8, 2) + "." + d.substr(5, 2) + "." + d.substr(0, 4),
    },
    mounted() {
        if(this.isNordwind){
            this.$root.metrika96799237.reachGoal("approved");
        }
        else if(this.appType('cash')) {
            this.$root.metrika88149184.reachGoal("approved");
        }
        else if(this.appType('vtb')) {
            this.$root.metrika97329470.reachGoal("approved");
        }
        this.$root.mindbox("Получил одобрение заявки");
        console.log('promocode anketa', this.anketa.promocode.promocodeOptions)
        // if(this.errorAuthTextApi.status){

        //     // this.scrollingToError()
        // }
    },
    methods: {
        openNextStep(template) {
            let data = {
                prevPage: "accessApplication",
                nextPage: template,
            };

            this.$emit("changeTemplate", data);
        },
        choiseRefusalService() {
            this.deleteInsurancePackage = !this.deleteInsurancePackage;
        },
        forceRerender() {
            this.componentKey += 1;
        },
        choosenTariff(id_tarif) {
            this.loading = true;
            this.sendInsurancePackage(id_tarif).then(() => {
                if (id_tarif !== 0) {
                    this.deleteInsurancePackage = false;
                    for (let k = 0; k < this.anketa.creditInformation.insuranceData.length; k++) {
                        if (this.anketa.creditInformation.insuranceData[k].id === id_tarif) {
                            this.anketa.creditInformation.insuranceData[k].insuranceSelected = true;
                        } else {
                            this.anketa.creditInformation.insuranceData[k].insuranceSelected = false;
                        }
                    }
                } else {
                    for (let k = 0; k < this.anketa.creditInformation.insuranceData.length; k++) {
                        this.anketa.creditInformation.insuranceData[k].insuranceSelected = false;
                    }
                }

                this.forceRerender();
                this.loading = false;
            })
        },
        sendInsurancePackage(id_tarif) {
            // return this.connector
            //     .getTokenAccess()
            //     .then((response) => {
            //         if (response) {
            //             localStorage.setItem("tokenAccess", response.access);
                        return this.connector
                            .isInsurancePackage(id_tarif)
                            .then((response) => {
                                console.log(response)
                                if (response.success) {
                                    this.anketa.creditInformation = response.info;
                                } else {
                                    return false;
                                }
                            })
                            .catch((error) => {
                                this.errorAuthTextApi.status = true;
                                this.errorAuthTextApi.text = error;
                            });
                    // } else {
                    //     this.errorAuthTextApi.status = true;
                    //     this.errorAuthTextApi.text = "";
                    // }
                // })
                // .catch((error) => {
                //     this.errorAuthTextApi.status = true;
                //     this.errorAuthTextApi.text = error;
                // });
        },

        sendRequestForSMSCode() {
            // console.log('ok', this.anketa.promocode.value === '' )
            // console.log('getPromocodeValue', this.promocode.getPromocodeValue())
            if(this.anketa.promocode.promocodeValue !== '' && this.anketa.promocode.promocodeOptions.ok){
                this.sendSavePromocode()
                // this.errorAuthTextApi.status = false;
                
                // this.openNextStep("smsPage");

                // this.connector
                //     .getTokenAccess()
                //     .then((response) => {
                //         if (response) {
                //             localStorage.setItem("tokenAccess", response.access);
                //             this.connector
                //                 .isRequestForSMSCode()
                //                 .then((response) => {
                //                     if (response.success) {
                //                         this.openNextStep("smsPage");
                //                     } else {
                //                         this.errorAuthTextApi.status = true;
                //                         this.errorAuthTextApi.text = response.message;
                //                         return false;
                //                     }
                //                 })
                //                 .catch((error) => {
                //                     this.errorAuthTextApi.status = true;
                //                     this.errorAuthTextApi.text = error;
                //                 });
                //         } else {
                //             this.errorAuthTextApi.status = true;
                //             this.errorAuthTextApi.text = "";
                //         }
                //     })
                //     .catch((error) => {
                //         this.errorAuthTextApi.status = true;
                //         this.errorAuthTextApi.text = error;
            //      });

            // this.connector
            //     .isRequestForSMSCode()
            //         .then((response) => {
            //             if (response.success) {
            //                 this.openNextStep("account");
            //             } else {
            //                 this.errorAuthTextApi.status = true;
            //                 this.errorAuthTextApi.text = response.message;
            //                 return false;
            //                 }
            //             })
            //             .catch((error) => {
            //                 this.errorAuthTextApi.status = true;
            //                 this.errorAuthTextApi.text = error;
            //             });

            
                        // this.openNextStep("smsPage");     
            }else if(this.anketa.promocode.promocodeValue === '' && !this.anketa.promocode.promocodeOptions.error) {
                this.callNextPage()
            }
            
        },

        callNextPage(){
            if(this.isNordwind){
                    this.$root.metrika96799237.reachGoal("get_money_approved");
            }else {
                this.$root.metrika88149184.reachGoal("get_money_approved");
            }
            this.$root.mindbox("Ознакомился с условиями займа");
            this.openNextStep("account");
        },

        appType(type){
            if(type === this.$root.state.type()){
                return true
            }else {
                return false
            }
        },

        showPromocodeHandler(e){
            console.log(e.currentTarget)
            console.log('autofocus', this.anketa.promocode)
            if(e.currentTarget){
                this.anketa.promocode.onFocus = true
                this.showPromocodeButton = false
            }
            // else {
            //     this.promocodeOptions.showButton = true
            // }
        },
        sendPromoVerify(){
            this.connector.promoVerify(this.anketa.promocode.value, this.anketa.phone.valueFirstDigitIs9())
                .then((response) => {
                    console.log('promo_verify response', response)
                    if(response.success && response.message.reason) {
                        this.anketa.promocode.promocodeOptions.sendRequest = false
                        this.anketa.promocode.promocodeOptions.error = false
                        this.anketa.promocode.promocodeValue = this.anketa.promocode.value
                        this.anketa.promocode.onFocus = false
                        this.anketa.promocode.promocodeInfo = 'Промокод применён'
                        this.anketa.promocode.promocodeOptions.ok = true
                        this.anketa.promocode.value = ''
                        this.anketa.promocode.showPromocodeLogo = false
                        this.anketa.promocode.isDisabled = true


                    }
                    else if(response.success && response.message.reason === false){
                        this.anketa.promocode.promocodeOptions.sendRequest = false
                        this.anketa.promocode.promocodeOptions.ok = false
                        this.anketa.promocode.onFocus = false
                        // this.promocodeInfo = 'Неверный промокод'
                        this.anketa.promocode.promocodeOptions.error = true
                        this.anketa.promocode.value = ''
                        this.anketa.promocode.showPromocodeLogo = false

                    }
                })
                .catch((error) => {
                    this.errorAuthTextApi.status = true;
                    this.errorAuthTextApi.text = error;
                    this.scrollingToError()
                });
        },

        sendSavePromocode(){
            this.connector.promoSave(this.anketa.promocode.promocodeValue, this.anketa.phone.valueFirstDigitIs9())
                .then((response) => {
                    console.log('promo_save response', response)
                    if(response.success && response.message.reason) {
                        this.anketa.promocode.promocodeOptions.sendRequest = false
                        this.anketa.promocode.promocodeOptions.error = false
                        // this.anketa.promocode.onFocus = false
                        // this.anketa.promocodeInfo = 'Промокод применён'
                        this.anketa.promocode.promocodeOptions.ok = true
                        this.anketa.promocode.showPromocodeLogo = false
                        
                        this.callNextPage()
                    }
                    else if(response.success && response.message.reason === false){
                        this.anketa.promocode.promocodeOptions.sendRequest = false
                        this.anketa.promocode.promocodeOptions.ok = false
                        // this.promocodeInfo = 'Неверный промокод'
                        this.anketa.promocode.promocodeOptions.error = true
                        this.anketa.promocode.showPromocodeLogo = false
                    }
                })
                .catch((error) => {
                    this.errorAuthTextApi.status = true;
                    this.errorAuthTextApi.text = error;
                    this.scrollingToError()
                });
        },

        scrollingToError(){
            const id = this.$refs.errorInfo
            setTimeout(() => {
                id.scrollIntoView({ behavior: "smooth"})
                
            }, 500);
        },

        clearPromoField(){
            this.anketa.promocode.showPromocodeLogo = true
            this.anketa.promocode.value = ''
            this.anketa.promocode.promocodeOptions.error = false
            this.showPromocodeButton = true

        },

        // errorTextHandler(){
        //     this.anketa.promocode.promocodeOptions.error = false
        //     this.anketa.promocode.onFocus = true
        // },

        // promocodeInfoHandler(){
        //     if(!this.anketa.promocode.isDisabled){
        //         this.anketa.promocode.onFocus = true
        //     }
        // }
    },
};
</script>
<style lang="scss" scoped>
.promocode {

    &_container {
        position: relative;
    }

    &_input :deep(input){
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 21.09px;
        letter-spacing: -0.4px;
        color: #0A0A0D;
        color: v-bind(promoFieldColor);
        border: 1px solid v-bind(promoFieldBorder);
        
        @media (max-width: 450px) {
            font-size: 16px;
            line-height: 18.75px;
        }
    }

    &_errors {
        color: 1px solid v-bind(promoFieldColor);
    }
    &_button {
        // width: 100%;
        position: absolute;
        top: 17px;
        right: 15px;
        width: 24px;
        height: 24px;

        & > img {
            width: 100%;
            height: 100%;
            
        }
        
    }
    //&_info{
    //     position: absolute;
    //     top: 20px;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     font-size: 16px;
    //     font-weight: 600;
    //     line-height: 18.75px;
    //     letter-spacing: -0.4px;
    //     color: #0A0A0D;

    //     display: flex;
    //     justify-content: space-between;
    //     gap: 10px;
    //     align-items: center;

    //     // &::before {
    //     //     content: '';
    //     //     background: url('./../../assets/img/promocode/promocode_img_cash.png');
    //     //     display: block;
    //     //     width: 20px;
    //     //     height: 18px;
    //     //     position: absolute;
    //     //     top: -3px;
    //     //     left: -30px;
    //     // }

    //     // & > img {
    //     //     position: absolute;
    //     //     // top: 10px;
    //     //     // left: -20px;
    //     // }
    // }

    &_fake-field-button {
        width: 100% !important;
        background-color: #fff !important;
        border: 1px solid #e7e7e7;
        border-radius: 12px;
        height: 56px !important;
    }

    &_next-step_button {
        @media (max-width: 450px) {
            font-size: 18px;
        }
        @media (max-width: 400px) {
            font-size: 16px;
        }
    }

    &_error {
        color: v-bind(promoFieldColor);
    }

}




// .promocode_input:deep(input) {
//     background-color: red ;
//     background-image: url('./../../assets/img/promocode/promocode_img.png') !important;
//     background-position: 38% 50% !important;
//     background-repeat: no-repeat !important;
//     // text-indent: 45%;
//     text-align: center;
    

//     &::placeholder {
//         font-size: 16px;
//         font-weight: 600;
//         line-height: 18.75px;
//         letter-spacing: -0.4px;
//         color: #0A0A0D;
//     }

//     &:focus::placeholder {
//         color: transparent;
//     }

//     &:focus {
//         background-image: none !important;
//     }
// }

</style>
