<template>
  <div class="pa-0 ma-0 conditions">
    <div
      :class="appType('vtb') ? 'vtb-info-limit_initpayment' : 'container-info-limit_initpayment'"
      class="vtb-info-limit "
      >
      <p
        :class="appType('vtb') ? 'vtb-info-limit_description' : 'container-info-limit_description'" 
        class="container_info_limit"
        >
        {{windowWidth > 420 ? 'Ежемесячный платеж' : 'Ежемес. платеж'}}
      </p>
      <div
        :class="appType('vtb') ? 'vtb-info-limit_price' : 'container-info-limit_price'" 
        >
        <p class="text" id="skip-value-payment">{{ conditionValue }}</p>
        <span class="ruble-icon">&#8381;</span>
      </div>
    </div>
  </div>
</template>

<script>
import { moneyFormatWithSpace } from "@/components/helpers/MoneyFormat.js";
export default {
  props: {
    value: [String, Number],
    valueClass: String,
    append: {
      type: String,
      default: "₽"
    },
    loading: Boolean

  },
  computed: {
    conditionValue() {
      return moneyFormatWithSpace(this.value);
    },
    windowWidth() {
      return this.$root.anketa.windowWidth;
    },
    isLoading() {
      return this.loading;
    },
  },
  methods: {
    appType(type){
      if(type === this.$root.state.type()){
        return true
      }else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vtb-variables.scss";
.vtb-info-limit_initpayment {
  
  margin-top: 32px;
}
.vtb-info-limit {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: baseline;
  // width: 100%;
  position: relative;
}
.vtb {
  &-info-limit_description {
    @include medium-text-thin;
    color: #2F3441;

    @media (max-width: 430px) {
      margin-right: 20px;
      text-align: left;

    }
    @media (max-width: 362px) {

      position: relative;
      top: -12px;
    }

    @media (max-width: 330px) {
      margin-right: 10px;
    }
  }
}

.container-info-limit_price {
  
  height: 40px;
  font-family: RobotoBold;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  margin: 0;
  white-space: nowrap;

  & > .text {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 48px;

    display: flex;
    align-items: center;
    font-family: NerisBlack;
    text-align: right;
    color: #0a0a0d;
    margin: 0;
  }

  & > span {
    font-size: 32px;
    line-height: 1.3em;
    display: flex;
    align-items: center;
    text-align: right;
    color: #0a0a0d;
    padding: 0 0 0 5px;
    font-weight: 900;
    transform: translateY(6.6%);
    font-family: SFProBold !important;
  }
}

.vtb-info-limit_price {
  @include bold-text;
  color: #2F3441;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 400px) {
    font-size: 30px;
  }
}
</style>