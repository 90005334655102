<template>
  <v-expansion-panels v-bind="$attrs" accordion>
    <v-expansion-panel v-show="accordion.action" v-for="(accordion, i) in accordions" :key="accordion.id">
      <v-expansion-panel-header :expand-icon="icon">
        <slot name="header"></slot>
        {{accordion.itemPanel}}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot></slot>
        <div class="accordion-body">
          <div class="accordion-body-item" v-html="text" v-for="(text, key) in accordionBody[i]" :key="key"></div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    accordions: {
      type: Array,
      default: () => [{ id: 0, action: true }]
    },

    icon: String,
  },

  computed: {
    accordionBody() {
      const panels = [];
        this.accordions.forEach(accordion => {
          if(!accordion.action ) {
            accordion.action = this.$root.anketa.actionInfo;
          }
          panels.push(accordion?.bodyPanel?.split(/\n/));
        })

      return panels;
    },
  }
}
</script>

<style lang="scss" scoped>
.v-expansion-panel-content {
  width: 100%;
}

.accordion-body-item {
  margin-bottom: 10px;
}
</style>