<template>
    <body :style="{overflow: anketa.isOpenModal ? 'hidden' : ''}">
      <div class="nordwind_container">
        <header class="nordwind_header">
          <div class="nordwind_header_inner">
            <img src="../assets/img/nordwind/Nordwind.svg" alt="nordwind logo">
            <div class="nordwind_header_inner_separator"></div>
            <img src="../assets/img/nordwind/Paylate.svg" alt="paylate logo">
          </div>
        </header>
        <div class="nordwind_container_outer">
          <div class="nordwind_container_inner">
            <div class="nordwind_container_info">
              <h2 class="nordwind_container_info_uptitle">Для вас доступна услуга:</h2>
              <h1 class="nordwind_container_info_title">Улётный кешбэк</h1>
              <ul class="nordwind_container_info_list">
                <li class="nordwind_container_info_item">вернём всю стоимость авиабилетов</li>
                <li class="nordwind_container_info_item">оплачивайте равными платежами</li>
                <li class="nordwind_container_info_item">получайте дополнительный кешбэк</li>
              </ul>
              <h3
                v-if="isNordwind && !checkingParams"
                class="nordwind_container_info_subtitle"
                >Для подачи заявки перейдите по ссылке из смс
              </h3>
            </div>
            <NordWindTemplateSlider
              v-if="urlParams && anketa.sliderParams"
              :reRenderComponent="trigger"
            />
          </div>
        </div>
      </div>
      <BlockHTML4 v-if="anketa.actionInfo" />
      <FormHTML />
      <BlockHTML5 />
  
    </body>
  </template>
  <script>
  // import '@/assets/scss/nordwind_styles/nordwind_style.scss';
  import '@/assets/scss/style.scss';
  import BlockHTML4 from "@/components/html/BlockHTML4";
  import BlockHTML5 from "@/components/html/BlockHTML5";
  import FormHTML from "@/components/modal/FormHTML";
  import NordWindTemplateSlider from './NordWindTemplateSlider.vue';
  
  export default {
    name: "NordwindMainPage",
    components: {
      BlockHTML4,
      BlockHTML5,
      FormHTML,
      NordWindTemplateSlider
    },
    data: () => ({
      trigger: false,
      color: 'red'
    }),
    methods: {
      changeTrigger() {
        this.trigger = !this.trigger;
      },
    },
    computed: {
      connector() {
        return this.$root.connector;
      },
      anketa() {
        return this.$root.anketa;
      },
      urlParams: {
        get() {
          let urlParams = new URLSearchParams(window.location.search);
          return urlParams.has('s');
        },
      },
      isNordwind(){
        return this.$root.state.isNordwind()
      },
      checkingParams(){
        let isHaveParams = null
        let findSearchParam = location.href.split('').findIndex(el => el === '?')
        if(findSearchParam === -1){
          isHaveParams = false
        }else {
          isHaveParams = true
        }
        return isHaveParams
      }
    },
  };
  </script>

  <style lang="scss" scoped>
    .nordwind {
    
      &_header {
        background-color: #AB292D;
        background-color: var(--v-primary-base);
        height: 80px;
        max-width: 1760px;
        margin: 0 auto 30px;
        width: 100%;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;

        &_inner {
          max-width: 1100px;
          width: 100%;
          display: flex;
          align-items: center;
          gap: 15px;
          padding: 0 20px;

          @media (max-width: 450px) {
            max-width: 400px;
          }

          & >img {  
            @media (max-width: 400px) {
              width: 45%;
              height: auto;
            }
          }
            
          &_separator {
            height: 31px;
            width: 2px;
            border-radius: 2px;
            background-color: #fff;

          }
        }
      }

    &_container {
        background-color: #fff;
        padding: 30px 0 30px;
        border-radius: 24px;

        &_outer {
          background: url('../assets/img/nordwind/bg_first.png');
          background-position-x: center;
          background-repeat: no-repeat;
          padding-top: 30px;
          border-radius: 24px;
          height: 720px;

          @media (max-width: 950px){
            padding-top: 0;
            height: unset;
            background: none;

          }
        }
        &_inner {
          max-width: 1100px;
          width: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          
          @media (max-width: 1200px) {
            padding: 0 20px; 
          }

          @media (max-width: 950px){
            flex-direction: column;
            align-items: center;
          }
        }
        &_info {
          max-width: 500px;
          margin-top: 150px;
          width: 100%;

          @media (max-width: 950px){
            margin-top: 0;
          }

          &_uptitle {
            color: #0A0A0D;
            font-family: Roboto;
            font-weight: 500;
            font-size: 34px;
            line-height: 56px;

            @media (max-width: 1000px) {
              font-size: 1.8rem;
            }
            @media (max-width: 950px){
              font-size: 20px;
              text-align: center;
            }
            

          }

          &_title {
            color: #D6181E;
            font-family: NerisBlack;
            font-size: 54px;
            line-height: 56px;
            letter-spacing: -1px;

            &::before{
              content: '\00AB';
            }

            &::after {
              content: '\00BB';
            }

            @media (max-width: 1000px) {
              font-size: 3rem;
            }

            @media (max-width: 950px){
              font-size: 30px;
              line-height: 30px;
              text-align: center;
              margin-bottom: 10px;
            }
          }

          &_subtitle {
            color: #D6181E;
            font-family: Neris;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 1px;
          }

          &_list {
            color: #0A0A0D;
            font-family: Roboto;
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;

            @media (max-width: 950px){
              font-size: 18px;
              line-height: 24px;
              margin-bottom: 20px;
            }

          }
        }
    }
}
  </style>
  