<template>
    <div :key='componentKey' class="vtb_template_slider">
        <div class='vtb_template_slider_inner' style="background: none;">
                    <div class='container-width '>
                        <div class='vtb_template_slider_body'>
                            <div class='block-2-announce'>
                                <div data-popmechanic-embed="72227"></div> 
                                <div class='container-info sliders sliders-position' id='widget' >
                                    <Slider
                                        v-model='anketa.initialPayment.value'
                                        v-bind='anketa.initialPayment'
                                        :nameSlider='sliderinitialPayment'
                                        :min='MinSum'
                                        :max='MaxSum'
                                        :step='1000'
                                        :sliderColors="sliderColors"
                                        @input='anketa.initialPayment.input'
                                        >
                                        <div class='container_info_limit slider-summ' slot='labelSliderSumm'>
                                            <p class='vtb-info-limit_description vtb-info-limit_description__ticks'
                                                >{{ minSumForDown }} &#8381;
                                            </p>
                                            <p class='vtb-info-limit_description vtb-info-limit_description__ticks'>
                                                {{ maxSumForDown }}
                                                <span>&#8381;</span>
                                            </p>
                                        </div>
                                    </Slider>
                                    <Slider
                                        v-model='anketa.term.value'
                                        v-bind='anketa.term'
                                        :tick-size='sliderPeriodSTR.length'
                                        :slider-array='sliderPeriod'
                                        :tick-labels='sliderPeriodSTR'
                                        :nameSlider='sliderTerm'
                                        :min='1'
                                        :max='sliderPeriod.length'
                                        :step='1'
                                        :sliderColors="sliderColors"
                                        @input='anketa.term.input'
                                        :metricGoalName="'slider_term'"
                                    />
                                    <Condition v-model='anketa.conditions.monthlyPayment'>Ежемесячный платеж</Condition>
                                    <!-- временная заглушка -->
                                    

                                    <!-- <div class="vtb_promocode_field_container">
                                        <FieldsCard :anketaNames="['vtbPromocode']" class="vtb_promocode_field"/>
                                        <button
                                            v-if="anketa.vtbPromocode.value !== ''"
                                            @click="anketa.vtbPromocode.value = ''"
                                            class="vtb_promocode_field_btn">
                                            <span></span>
                                        </button>
                                        <p 
                                            :class="this.textToShow.class"
                                            class=" mt-4 text-left"
                                            >{{ this.textToShow.text }}
                                        </p>
                                    </div> -->


                                    <!-- конец заглушки -->
                                    <p></p>
                                    <div v-if='anketa.actionInfo && params === "ac"' class='container-info-cashback'>
                                        <div class='container-info-cashback-title'>
                                            <h1 class="text-left">
                                                Ваш кешбэк: {{ anketa.term.valueTerm === 12 ? cashback : "0" }}
                                                <b class='ruble-icon'>&#8381;</b>
                                            </h1>
                                        </div>
                                            <p class="vtb-cashback mt-4">
                                                {{anketa.term.valueTerm === 12 ? "": "Чтобы получить кешбэк, выберите срок от 3х месяцев"}}
                                            </p>
                                            <p class="pointer more" @click="$vuetify.goTo('#cashback', 500)">
                                                Подробнее
                                            </p>
                                    </div>
                                    <div v-if='anketa.actionInfo && params === "acnew"' class='container-info-cashback'>
                                        <div class='container-info-cashback-title'>
                                            <h1>
                                                Ваш кешбэк: {{ anketa.term.valueTerm === 1 ? "0" : cashback }}
                                                <b class='ruble-icon'>&#8381;</b>
                                            </h1>
                                        </div>
                                        <p class="vtb-cashback mt-4">
                                            {{anketa.term.valueTerm === 1 ? "Чтобы получить кешбэк, выберите срок от 3х месяцев": ""}}
                                        </p>
                                        
                                        <p class="pointer more" @click="$vuetify.goTo('#cashback', 500)">
                                            Подробнее
                                        </p>
                                    </div>
                                    <v-btn
                                        class='vtb-button vtb-button-prime modal-open'
                                        data-modal='form-modal'
                                        id='get-money'
                                        @click='changeStatusModal()'
                                        >Получить 
                                        <span class="vtb-button_ammount"  id='button-value-amount'>
                                            {{ anketa.conditions.initialPayment.toLocaleString().replace(/,/, " ") }}
                                            <b class='ruble-icon'>&#8381;</b>
                                        </span>
                                    </v-btn>
                                    
                                </div>
                                <div
                                    v-show="!showSliderComplited.status && !showSliderComplited.error.status"
                                    class='preloader'
                                    >
                                    <img class src="@/assets/img/vtb/vtb_preloader-two.gif"/>
                                </div>
                                <div
                                    v-show='showSliderComplited.error.status'
                                    class="error-text"
                                    >
                                    <p>{{ showSliderComplited.error.text }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                <!-- </div> -->
            </div>
        <!-- </div> -->
    </div>
</template>

<script>
import Slider from "@/components/Slider";
import Condition from "@/components/Condition.vue";
import {moneyFormatWithSpace} from "@/components/helpers/MoneyFormat.js";

// Добавить при добавлении промокода
// import FieldsCard from "@/components/FieldsCard";

export default {
    name: "VtbTemplateSlider",
    components: {
        Slider,
        Condition,

        // Добавить при добавлении промокода
        // FieldsCard
        
    },
    props: {
        countPeriod: Number,
        reRenderComponent: Boolean,
    },
    data: () => ({
        componentKey: 0, //для принудительного повторного рендинга компонента
        showSliderComplited: {
            status: false,
            error: {
                status: false,
                text: "Системная ошибка",
            },
        },
        MinSum: 3000,
        MaxSum: 60000,
        sliderPeriod: [1],
        sliderPeriodSTR: ["1"],
        sliderinitialPayment: {
            name: "Сумма",
            unit: "&#8381;",
            useModel: true,
        },
        sliderTerm: {
            name: "Срок",
            unit: "мес.",
            useModel: false,
        },
        params: '',
        textComands: {
            info: 'Уточните у сотрудника в отделении',
            error: 'Неверный код, обратитесь к сотруднику в отделение',
            ok: 'Код успешно применён'
        },
        textToShow: {
            text: 'Уточните у сотрудника в отделении',
            class: 'vtb_promocode_info'
        },
        promocodeIsUsed: false

    }),
    watch: {
        reRenderComponent: function () {
            this.forceRerender();
        },
    },
    methods: {
        changeStatusModal() {
            this.$root.metrika97329470.reachGoal("get_money");
            this.$root.mindbox("Выбрал сумму на калькуляторе");
            //--- добавить при добавлении промокода

            // this.vtbPromocodeInfo()
            // if(this.promocodeIsUsed === true){
            //     this.$root.anketa.isOpenModal = true;

            // }

            //----
            this.$root.anketa.isOpenModal = true;

        },
        forceRerender() {
            this.componentKey += 1;
        },
        limitSumDefinitions(urlParams) {
            if(urlParams.get("e")) this.$root.mindbox("Посетил страницу. Лимит 15k");
            if(urlParams.get("w")) this.$root.mindbox("Посетил страницу. Лимит 30k");
            if(urlParams.get("o")) this.$root.mindbox("Посетил страницу. Лимит 50k");
            if(urlParams.get("h")) this.$root.mindbox("Посетил страницу. Лимит 80k");
            if(urlParams.get("t")) this.$root.mindbox("Посетил страницу. Лимит 100k");
            if(urlParams.get("m")) this.$root.mindbox("Посетил страницу. Лимит 150k");
            if(urlParams.get("r")) this.$root.mindbox("Посетил страницу. Лимит 100k. NearPrime");
            if(urlParams.get("y")) this.$root.mindbox("Посетил страницу. Лимит 500k. NearPrime");
            if(urlParams.get("nr")) this.$root.mindbox("Посетил страницу. Лимит 20к. Нерезидент");
        },
        getFirstDate(urlParams) {
            this.connector
                .isMainInformationCalc(urlParams.get("s"))
                .then((response) => {
                    if (response.success && response.slider_params.maxSum !== 0) {
                        this.showSliderComplited.status = true;
                        this.anketa.firstDateSlider = this.getObjSort(response.slider_params.listStocks);

                        if (urlParams.get("ac") !== null) {
                            this.anketa.actionInfo = true;
                            this.params = 'ac'
                        }

                        else if (urlParams.get("acnew") !== null) {
                            this.anketa.actionInfo = true
                            this.params = 'acnew'
                        }
                        for (let k in this.anketa.firstDateSlider) {
                            this.anketa.firstDateSlider[k].periodStr = String(this.anketa.firstDateSlider[k].period);
                        }
                        this.sliderPeriod = this.getsliderPeriod(this.anketa.firstDateSlider);

                        this.sliderPeriodSTR = this.getsliderPeriodSTR(this.anketa.firstDateSlider);
                        this.MaxSum = response.slider_params.maxSum;
                        this.MinSum = response.slider_params.minSum;

                        if (urlParams.get("sum")) {
                            this.anketa.initialPayment.value = +urlParams.get("sum") * 1000;
                        } else {
                            this.anketa.initialPayment.value = response.slider_params.maxSum;
                        }

                        for (let i in this.sliderPeriod) {
                            if (this.sliderPeriod[i] == response.slider_params.defaultPeriod) {
                                this.anketa.term.value = parseInt(i) + 1;
                            }
                        }
                        this.anketa.conditions.calculate(this.anketa.initialPayment.value, this.anketa.term.value);
                        return this.anketa.firstDateSlider;
                    } else {
                        this.anketa.sliderParams = false;
                    }
                })
                .catch((error) => {
                    this.showSliderComplited = {
                        status: false,
                        error: {
                            status: true,
                            text: error,
                        },
                    };
                });
        },
        getObjSort(obj) {
            return obj.sort((a, b) => (a.period > b.period ? 1 : -1));
        },
        getsliderPeriod(obj) {
            let array = [];
            for (let k in obj) {
                array.push(obj[k].period);
            }
            return array;
        },
        getsliderPeriodSTR(obj) {
            let array = [];
            for (let k in obj) {
                array.push(obj[k].periodStr);
            }
            return array;
        },

        vtbPromocodeInfo(){
            
            if(this.anketa.vtbPromocode.value === '') {
                this.textToShow.text = this.textComands.error
                this.textToShow.class = 'vtb_promocode_info-error'
                this.promocodeIsUsed = false
            }
            else if(this.anketa.vtbPromocode.value.length !== 6) {
                this.textToShow.text = this.textComands.error
                this.textToShow.class = 'vtb_promocode_info-error'
                this.promocodeIsUsed = false
            }
            else if(this.anketa.vtbPromocode.value.length === 6) {
                this.textToShow.text = this.textComands.ok
                this.textToShow.class = 'vtb_promocode_info'
                this.promocodeIsUsed = true

            }


        }
    },

    computed: {
        anketa() {
            return this.$root.anketa;
        },
        connector() {
            return this.$root.connector;
        },
        cashback: {
            get() {
                return moneyFormatWithSpace((this.anketa.initialPayment.value / 100) * this.anketa.term.percent * (this.anketa.term.valueTerm - 1));
            },
        },

        minSumForDown() {
            return moneyFormatWithSpace(this.MinSum);
        },

        maxSumForDown() {
            return moneyFormatWithSpace(this.MaxSum);
        },

        minTerm: {
            get() {
                return Math.min.apply(null, this.sliderPeriod);
            },
        },
        maxTerm: {
            get() {
                return Math.max.apply(null, this.sliderPeriod);
            },
        },
        templateText: {
            set() {
                return this.anketa.conditions.initialPayment > 40000 ? false : true;
            },
            get() {
                return this.anketa.conditions.initialPayment > 40000 ? false : true;
            },
        },

        getUrlParams() {
          let urlParams = new URLSearchParams(window.location.search);
          console.log(urlParams)
          return urlParams.has('s');
        },

        sliderColors(){
            let colors = this.$root.$vuetify.theme.defaults.light
            return {
                bg: colors.primary,
                track: '#c8c8c8'
            }
        },
    },
    created() {
        let urlParams = new URLSearchParams(window.location.search);
        this.anketa.mindboxId = urlParams.get("c");
        this.getFirstDate(urlParams);
        this.limitSumDefinitions(urlParams);
        // this.vtbPromocodeInfo()
        console.log(this.getUrlParams)
        console.log('vtb colors', this.sliderColors)
        console.log('vuetify vtbtemplateslider', this.$root.$vuetify.theme.defaults.light.primary)
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vtb-variables.scss";

.container-info {
    width: 100%;
}

.vtb {
    &_template_slider {
        max-width: 500px;
        width: 100%;
        &_body {
            background-color: #fff;
            padding: 25px;
            border: 1px solid #D4D7DF;
            border-radius: 8px;
        }
    }

    &_input {
        border: 2px var(--v-primary-base) solid;
        border-radius: 8px;
        
    }

    &-button {
        &_ammount {
            margin-left: 10px;
        }
    }

    &-cashback {
        @include small-text-middle;
        color: #2F3441;
        text-align: left;
    }

    &_promocode_info {
        @include small-text-middle;
        color: #2F3441;
        

        @media (max-width: 400px) {
            font-size: 12px;
        }

        &-error {
            @include small-text-middle;
            color: red;

            

            @media (max-width: 400px) {
                font-size: 12px;
            }
        }
    }
} 


.vtb_promocode_field {
    border: 2px solid #0A2896;
    height: 52px;
    width: 100%;
    border-radius: 8px;
    padding: 0 0 0 20px;
    // font-size: 20px;
    // line-height: 21px !important;
    // font-weight: 600;
    // color: var(--v-primary-base) !important;

    &_container {
        margin-top: 30px;
    }

    & :deep(.v-input__control) {
        min-height: 0 !important;
    }

    & :deep(.row) {
        margin: 0;
        
        & .col {
            padding: 0;

        }
    }

    &::placeholder{
        font-weight: 600;
        color: var(--v-primary-base);
    }

    & :deep(input) {
        padding: 0;
        color: var(--v-primary-base);
        font-weight: 600;
        font-size: 20px;
        line-height: 21px;
        letter-spacing: -0.4px;

        &::placeholder {
            font-weight: 600;
            color: var(--v-primary-base); 
            
            font-size: 20px;
            line-height: 21px;

        }
    }

    &_container {
        position: relative;
    }

    &_btn {
        // display: none;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 15px;
        right: 30px;
        // background-color: red;
        
        & > span {
            display: block;
            width: 16px;
            height: 3px;
            transform: rotate(-45deg);

            background-color: #0A2896;
            &::after {
                display: block;
                content: '';
                width: 16px;
                height: 3px;
                transform: rotate(90deg);
                background-color: #0A2896;
            }
        }
    }
}

.container-info-cashback {
    background-color: #EEF4F8 !important;
    // margin-top: 30px;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;

    &-title {

        & > h1 {
            @include semibold-text-calculate;
            color: #2F3441;

        }
    }

    & > .pointer {
        @include small-text-middle;
        color: var(--v-primary-base);
    }
}

.vtb_input {

    @include bold-input;
    color: var(--v-primary-base);
    // &::placeholder {
    //     color: var(--v-primary-base);
    // }

    color: var(--v-primary-base) !important;
    padding: 0 20px 0;
    
    & .v-icon {
        margin-top: 0 !important;
    } 
     .v-input_slot {
            box-shadow: none !important;
    }

}

.vtb-info-limit_description {
    @include small-text;
    color: #606981;

    &:first-child {
            text-align: left !important;
        }

        &:last-child {
            text-align: right !important;
        }
}

// .vtb-info-limit_description__ticks {
//     @include small-text;
//     color: red;
// }
.container_info_limit {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: baseline;
}

.container {
    padding: 0 !important;
}


</style>
