<template>
  <!--block4-->
  <div
    class=" block-4"
    id="calculated"
  >
    <div class="container-width">
      <div class="block-4-body" id="cashback">
        <h1 v-if="appType('cash')">Как получить кешбэк</h1>
        

        <div v-if="appType('cash')" class="discount-info">
          <div v-if="urlParams('ac')" class="discount-info-item discount-item-1">
            <div class="discount-info-block">
              <img
                class="discount-image"
                src="../../assets/img/period-toggle.svg"
              >
            </div>
            <p class="discount-info-text">Срок займа 12 месяцев
            </p>
          </div>
          <div v-if="urlParams('acnew')" class="discount-info-item discount-item-1">
            <div class="discount-info-block">
                <img
                  class="discount-image"
                  src="../../assets/img/period-toggle-acnew.svg"
                  alt=""
                >
            </div>
             <p class="discount-info-text">Срок кредита<sup class="other">*</sup>  от 3х месяцев
             </p>
          </div>
          <div class="discount-info-item discount-item-2">
            <div class="discount-info-block">
              <img
                class="discount-image"
                src="../../assets/img/days-counter.svg"
                alt=""
              >
            </div>
            <p class="discount-info-text">Ежемесячно погашайте не позднее чем за 10 дней до даты платежа
            </p>
          </div>
          <div class="discount-info-item discount-item-3">
            <div class="discount-info-block">
              <img
                class="icon-image"
                src="../../assets/img/icon8.svg"
              >
            </div>
            <p
                v-if="urlParams('ac')"
                class="discount-info-text">Верните все уплаченные проценты кешбэком через 11 месяцев
            </p>
            <p
                v-if="urlParams('acnew')"
                class="discount-info-text">Верните все уплаченные проценты кешбэком
            </p>
          </div>
        </div>

        <div v-if="appType('nordwind')" class="nordwind-discount">
          <h1 v-if="appType('nordwind')" class="nordwind-discount_title">Летайте с выгодой</h1>
          <div v-if="appType('nordwind')" class="discount-info">

            <div v-if="urlParams('ac')" class="nordwind-discount_info_item discount-item-1">
              <div class="discount-info-block">
                <img
                  class="discount-image"
                  src="@/assets/img/nordwind/nordwind_icon1.svg"
                >
              </div>
              <p class="discount-info-text">Не нужно оформлять новый финансовый продукт. Перечислим деньги на вашу карту за 2 минуты
              </p>
            </div>

            <div v-if="urlParams('acnew')" class="nordwind-discount_info_item discount-item-1">
              <div class="discount-info-block">
                  <img
                    class="discount-image"
                    src="@/assets/img/nordwind/nordwind_icon1.svg"
                    alt=""
                  >
              </div>
               <p class="discount-info-text">Не нужно оформлять новый финансовый продукт. Перечислим деньги на вашу карту за 2 минуты
               </p>
            </div>

            <div class="nordwind-discount_info_item discount-item-3">
              <div class="discount-info-block">
                <img
                  class="discount-image"
                  src="@/assets/img/nordwind/nordwind_icon3.svg"
                >
              </div>
              <p
                  v-if="urlParams('ac')"
                  class="discount-info-text">Вернём стоимость авиабилетов, оплаченных ранее вашими собственными средствами
              </p>
              <p
                  v-if="urlParams('acnew')"
                  class="discount-info-text">Вернём стоимость авиабилетов, оплаченных ранее вашими собственными средствами
              </p>
            </div>
            <div class="nordwind-discount_info_item discount-item-2">
              <div class="discount-info-block">
                <img
                  class="discount-image"
                  src="@/assets/img/nordwind/nordwind_icon2.svg"
                  alt=""
                >
              </div>
              <p class="discount-info-text">Вносите ежемесячные платежи согласно графику или оплачивайте всю сумму в удобное для вас время
              </p>
            </div>
          
          </div>
        </div>


        <v-btn
          v-if="!appType('vtb')"
          class=" modal-open discount-button button_center rectangle-button"
          data-modal="form-modal"
          @click="$vuetify.goTo('#widget', 500)"
          >Получить деньги
        </v-btn>
        <a
          v-if="!appType('vtb') && urlParams('ac')"
          class="discount-terms"
          href="./docs/index.pdf"
          target="_blank"
          >Полные условия акции
        </a>
        <a
          v-if="!appType('vtb') && urlParams('acnew')"
          class="discount-terms"
          href="./docs/loan0.pdf"
          target="_blank"
          >Полные условия акции
        </a>


        <div class="vtb" v-if="appType('vtb')">
          <div class="vtb-discount">
            <h2 class="vtb-discount_title">Как получить кешбэк</h2>
            <div class="discount-info">
              <div v-if="urlParams('ac')" class="vtb-discount_info_item discount-item-1">
                <div class="discount-info-block">
                  <img
                    class="discount-image"
                    src="@/assets/img/vtb/vtb-clock.svg"
                  >
                </div>
                <p class="vtb-discount-info-text first_child">Срок займа 3, 6, 10 или 12 мес.
                </p>
              </div>
              <div v-if="urlParams('acnew')" class="vtb-discount_info_item discount-item-1">
                <div class="discount-info-block">
                    <img
                      class="discount-image"
                      src="../../assets/img/vtb/vtb-clock.svg"
                      alt=""
                    >
                </div>
                 <p class="vtb-discount-info-text first_child">Срок займа 3, 6, 10 или 12 мес.
                 </p>
              </div>
              <div class="vtb-discount_info_item discount-item-3">
                <div class="discount-info-block">
                  <img
                    class="discount-image"
                    src="@/assets/img/vtb/vtb-calendar.svg"
                  >
                </div>
                <p
                    v-if="urlParams('ac')"
                    class="vtb-discount-info-text">Ежемесячно погашайте не позднее чем за 10 дней до даты платежа
                </p>
                <p
                    v-if="urlParams('acnew')"
                    class="vtb-discount-info-text">Ежемесячно погашайте не позднее чем за 10 дней до даты платежа
                </p>
              </div>
              <div class="vtb-discount_info_item discount-item-2">
                <div class="discount-info-block">
                  <img
                    class="discount-image"
                    src="@/assets/img/vtb/vtb-wallet.svg"
                    alt=""
                  >
                </div>
                <p class="vtb-discount-info-text last_child">Отправьте заявку на возврат средств
                </p>
              </div>
            
            </div>
          </div>
          <v-btn
            class=" modal-open discount-button  vtb-button"
            data-modal="form-modal"
            @click="$vuetify.goTo('#widget', 500)"
            >Получить деньги
          </v-btn>
          <a
            v-if="urlParams('ac')"
            class="vtb_link"
            href="./docs/index.pdf"
            target="_blank"
            >Полные условия акции
          </a>
          <a
            v-if="urlParams('acnew')"
            class="vtb_link"
            href="./docs/loan0.pdf"
            target="_blank"
            >Полные условия акции
          </a>

          <div class="vtb_second-block">
            <h2 class="vtb_second-block_title">Погасить кредит легко</h2>
            <div class="vtb_second-block_info">

              <div class="vtb_second-block_info_item">
                <div class="vtb_second_flex">
                  <h3 class="vtb_second-block_info_item_title">Банковская карта</h3>
                  <img 
                    class="vtb_second-block_info_item_image"
                    src="@/assets/img/vtb/vtb-card.svg" 
                  />
                </div>
                <p class="vtb_second-block_info_item_text">
                  <!-- Оплачивайте с помощью VISA, MasterCard и Мир -->
                  На сайте, в Личном кабинете или в мобильном приложении Paylate
                </p>
              </div>
              <div class="vtb_second-block_info_item">
                <div class="vtb_second_flex">
                  <h3 class="vtb_second-block_info_item_title">Электронный кошелек</h3>
                  <img 
                    class="vtb_second-block_info_item_image"
                    src="@/assets/img/vtb/vtb-done.svg" 
                  />
                </div>
                <p class="vtb_second-block_info_item_text">
                  <!-- Через терминалы Элекснет либо через online кабинет -->
                  Через терминалы или online-кабинет Элекснет
                </p>
              </div>
              <div class="vtb_second-block_info_item">
                <div class="vtb_second_flex">
                  <h3 class="vtb_second-block_info_item_title">Наличные</h3>
                  <img 
                    class="vtb_second-block_info_item_image"
                    src="@/assets/img/vtb/vtb-checkpoint.svg" 
                    />
                </div>
                <p class="vtb_second-block_info_item_text">
                  <!-- Погашайте задолженность без комиссии в банкоматах и в отделениях банков -->
                  Погашайте задолженность без комиссии в <a href="https://elecsnet.ru/terminals/" target="_blank">терминалах</a> и <a href="https://www.psbank.ru/Office?st=1&ot=0&aot=0" target="_blank">банкоматах</a>
                </p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "BlockHTML4",
  computed: {
    getUrlParams(){
      const urlLocation = location.search.split('&')
      return urlLocation[1]
    },
    // isNordwind(){
    //   return this.$root.state.isNordwind()
    // }
  },
  methods: {
    urlParams(param){
      const urlLocation = location.search.split('&')
      return urlLocation.includes(param)
    },

    appType(type){
      if(type === this.$root.state.type()){
        return true
      }else {
        return false
      }
    }
  // openFile() {
  // window.open(require('../../assets/docs/index.pdf'), '_blank')
  // }
  },
  mounted(){
    console.log(this.getUrlParams)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vtb-variables.scss";

.button_center {
  margin: 0 auto;
  
}

.nordwind-discount {
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 3px 5px rgba(95, 99, 104, 0.05),
  0px 10px 10px rgba(95, 99, 104, 0.05),
  0px 1px 18px rgba(95, 99, 104, 0.05);
  margin-bottom: 50px;

  &_title {
    padding: 0 20px;
    @media (max-width: 500px) {
      font-size: 30px;
      padding: 20px 20px 0;
    }
  }
}

.nordwind-discount_info_item {
  max-width: 336px;
  width: 100%;
  padding: 32px;
  min-height: 272px;
  margin: 0 16px 0 16px;
  border-radius: 16px;
  position: relative;

  .discount-info-block {
    height: 100px;

    img {
      max-height: 100%;
    }
  }

  .container-info-limit {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: baseline;

    .container-info-limit_description {
      width: 50%;
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 1.3em;
      display: flex;
      align-items: center;
      color: #333333;

      margin-bottom: 18px;
    }

      .container-info-limit_price {
        border-radius: 8px;
        height: 40px;
        font-family: RobotoBold;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 50%;
        margin: 0;

        p {
          font-family: NerisBlack;
          font-style: normal;
          font-weight: 900;
          font-size: 32px;
          line-height: 1.4em;
          display: flex;
          align-items: center;
          text-align: right;
          color: #0a0a0d;
          margin-bottom: 0 !important;
        }

        span {
          font-family: NerisBlack;
          font-style: normal;
          font-weight: 900;
          font-size: 32px;
          line-height: 1.4em;
          display: flex;
          align-items: center;
          text-align: right;
          color: #0a0a0d;
          padding: 0 0 2px 5px;
        }
      }
    }
  }

  .nordwind-discount {
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0px 3px 5px rgba(95, 99, 104, 0.05),
    0px 10px 10px rgba(95, 99, 104, 0.05),
    0px 1px 18px rgba(95, 99, 104, 0.05);
    margin-bottom: 50px;

  &_title {
    padding: 0 20px;
    @media (max-width: 500px) {
      font-size: 30px;
      padding: 20px 20px 0;
    }
  }
}

.vtb {
  max-width: 1100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-discount {
    border: 1px solid #F3F7FA;
    border-radius: 8px;
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0px 3px 5px rgba(95, 99, 104, 0.05),
    0px 10px 10px rgba(95, 99, 104, 0.05),
    0px 1px 18px rgba(95, 99, 104, 0.05);
    padding: 34px 20px 0;
    margin-bottom: 60px;
    width: 100%;
    
    &-info {
      display: flex;
      justify-content: space-between;
      gap: 40px;
    }

    &_title {
      @include big-text;
      color: #2F3441;
      margin: 0;

      @media (max-width: 475px){
        @include semibold-text;
      }
      @media (max-width: 416px){
        line-height: 36px;
      }
    }

    &-info-text {
      @include medium-text;
      color: #2F3441;

      
    }

    &_info_item {
      max-width: 336px;
      width: 100%;
      // padding: 32px;
      // min-height: 272px;
      // margin: 0 16px 0 16px;
      border-radius: 16px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      

      .discount-info-block {
        height: 100px;
        margin-bottom: 30px;

        img {
          max-height: 100%;
        }
      }

      .container-info-limit {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: baseline;

        .container-info-limit_description {
          width: 50%;
          font-family: Roboto;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 1.3em;
          display: flex;
          align-items: center;
          color: #333333;

          margin-bottom: 18px;
        }

          .container-info-limit_price {
            border-radius: 8px;
            height: 40px;
            font-family: RobotoBold;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            width: 50%;
            margin: 0;
          }
        }
    }
  }

  &_second-block {
    margin-top: 60px;
    
    &_title {
      @include big-text;
      color: #2F3441;
      margin-bottom: 50px;

      @media (max-width: 475px){
        @include semibold-text;
      }
      
    }

    &_info {
      display: flex;
      justify-content: space-between;
      @media (max-width: 900px) {
        flex-direction: column;
        align-items: center;
      }
      &_item {
        max-width: 366px;
        width: 100%;
        height: 260px;
        border: 1px solid #D4D7DF;
        border-radius: 9px;
        padding: 30px 20px;

        @media (max-width: 900px) {
          max-width: 500px;
        }

        &_title {
          @include semibold-text;
          color: #0A2896;
          width: 60%;
          line-height: 25px;
          @media (max-width: 550px) {
            font-size: 28px;
            line-height: 24px;
          }
        }

        &_text {
          @include medium-text;
          color: #606981;
          margin-top: 20px;
          
          & > a {
            color: #0A2896 !important;
          }
        }

      }
    }
  }
  
  &_second_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_link {
    @include medium-text-thin;
    color: #606981;
    margin-top: 30px;
  }
}

.first_child{
  max-width: 180px;
  width: 100%;
  
  @media (max-width: 1124px) {
    max-width: unset;
  }
}
.last_child{
  max-width: 165px;
  width: 100%;
  
  @media (max-width: 1124px) {
    max-width: unset;
  }
  @media (max-width: 415px) {
    max-width: 240px;
  }
}
</style>
