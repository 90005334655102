<template>
  <div v-if="visible">
    <h4 class="block-title text-left mb-3">{{ title }}</h4>
    
    <v-row>
      <Field
        v-for="name in fields"
        :key="name"
        :anketaName="name"
        :altCols="name.grid"
      />
    </v-row>
  </div>
</template>

<script>
import Field from "@/components/Field";

export default {
  components: {Field,},
  props: {
    fields: Array,
    title: String,
    conditions: Array
  },
  
  computed: {
    additionalData() {
      return this.$root.anketa.additionalData;
    }
  },
  
  data: () => ({
    visible: true
  }),
  
  created() {
    let count = 0;
    
    this.conditions.forEach(condition => {
      if (this.additionalData[condition]) {
        count++
      }
    })
    
    if (count === 0) {
      this.visible = false;
    }
  }
}
</script>

<style scoped>

</style>